import * as React from "react";
import Navbar from "./nav";

function Shop() {
  return (
    <>
      <Navbar />
      <div className="shop">
        <div className="shop-div-6">
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/10e0977a5c12a3abe119ace854f93a1da83fb0bd030e02c7a56df6058c034196?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/10e0977a5c12a3abe119ace854f93a1da83fb0bd030e02c7a56df6058c034196?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/10e0977a5c12a3abe119ace854f93a1da83fb0bd030e02c7a56df6058c034196?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/10e0977a5c12a3abe119ace854f93a1da83fb0bd030e02c7a56df6058c034196?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/10e0977a5c12a3abe119ace854f93a1da83fb0bd030e02c7a56df6058c034196?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/10e0977a5c12a3abe119ace854f93a1da83fb0bd030e02c7a56df6058c034196?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/10e0977a5c12a3abe119ace854f93a1da83fb0bd030e02c7a56df6058c034196?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/10e0977a5c12a3abe119ace854f93a1da83fb0bd030e02c7a56df6058c034196?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
            className="img-2"
          />
          <div className="shop-div-7">
            <div className="shop-div-8">
              <div className="elegance-in-every-piece">
                Elegance in Every Piece
              </div>
              <div className="explore-argentums-exclusive-silver-jewelry-collection-crafted-with-precision-and-sustainability-in-mind-each-piece-reflects-our-commitment-to-luxury-and-eco-conscious-values-blending-timeless-design-with-a-polished-finish">
                Explore Argentum’s exclusive silver jewelry collection, crafted
                with precision and sustainability in mind. Each piece reflects
                our commitment to luxury and eco-conscious values, blending
                timeless design with a polished finish.{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="our-products">Our Products</div>
        <div className="shop-div-9">
          <div className="shop-div-10" />
          <div className="shop-div-11">
            <div className="showing-8-of-50-products">
              Showing 8 of 50 products{" "}
            </div>
            <div className="shop-div-12">
              <div className="sort-by">Sort By</div>
              <div className="shop-div-13">
                <div className="shop-div-14">
                  <div className="best-selling">Best Selling</div>
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/04661fcfb4d8331dd810e7fba120433ef98759b7f589ed6cda78195384e3ae01?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                    className="img-3"
                  />
                </div>
                <div className="shop-div-15" />
              </div>
            </div>
          </div>
          <div className="shop-div-16">
            <div className="shop-div-17">
              <div className="shop-div-18">
                <div className="shop-div-19">
                  <div>Type</div>
                  <div className="">+</div>
                </div>
                <div className="shop-div-20" />
              </div>
              <div className="shop-div-21">
                <div className="shop-div-22">
                  <div>Price</div>
                  <div>+</div>
                </div>
                <div className="shop-div-23" />
              </div>
              <div className="shop-div-24">
                <div className="shop-div-25">
                  <div>Gender</div>
                  <div>+</div>
                </div>
                <div className="shop-div-26" />
              </div>
              <div className="shop-div-27">
                <div className="shop-div-28">
                  <div>Weight</div>
                  <div className="">+</div>
                </div>
                <div className="shop-div-29" />
              </div>
            </div>
            <div className="shop-div-30">
              <div className="shop-div-31">
                <div className="shop-div-32">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/21bfd4eab2f9020f77705ad8782bc2284ecd48c2bcefbc9242980c3e74a11b2c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/21bfd4eab2f9020f77705ad8782bc2284ecd48c2bcefbc9242980c3e74a11b2c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/21bfd4eab2f9020f77705ad8782bc2284ecd48c2bcefbc9242980c3e74a11b2c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/21bfd4eab2f9020f77705ad8782bc2284ecd48c2bcefbc9242980c3e74a11b2c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/21bfd4eab2f9020f77705ad8782bc2284ecd48c2bcefbc9242980c3e74a11b2c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/21bfd4eab2f9020f77705ad8782bc2284ecd48c2bcefbc9242980c3e74a11b2c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/21bfd4eab2f9020f77705ad8782bc2284ecd48c2bcefbc9242980c3e74a11b2c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/21bfd4eab2f9020f77705ad8782bc2284ecd48c2bcefbc9242980c3e74a11b2c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                    className="img-4"
                  />
                  <div className="silver-necklace">Silver Necklace</div>
                  <div className="120-55">$120.55</div>
                  <div className="shop-div-33">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/f548d5fd28d325f73500d64b594534cc810ee4f383d4a1b91cda872241e2df46?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-5"
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/13dd9131d5144d2ec3b94eefb3f70e0bfd08e322f5a7410b8c47730b9df5e86a?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-6"
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/5432a4a73b3198b545512ad115e34ada15ba01c2e0ec1009f83eb2a23e36310e?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-7"
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/403e49860a8b5f9a0265d90b705e47dd31d888ac94495c16c5b55eefb040588b?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-8"
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/ac2b0b90e5deb264e5c2db57ea3900c17aafac865b13a2327737772c7f220dfd?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-9"
                    />
                  </div>
                  <div className="add-to-cart">Add to Cart</div>
                </div>
                <div className="shop-div-34">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/251a6eeab40f7b6d09d05d28b9b8aff3c2342c31de1572cf26cc9aa1457b0325?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/251a6eeab40f7b6d09d05d28b9b8aff3c2342c31de1572cf26cc9aa1457b0325?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/251a6eeab40f7b6d09d05d28b9b8aff3c2342c31de1572cf26cc9aa1457b0325?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/251a6eeab40f7b6d09d05d28b9b8aff3c2342c31de1572cf26cc9aa1457b0325?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/251a6eeab40f7b6d09d05d28b9b8aff3c2342c31de1572cf26cc9aa1457b0325?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/251a6eeab40f7b6d09d05d28b9b8aff3c2342c31de1572cf26cc9aa1457b0325?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/251a6eeab40f7b6d09d05d28b9b8aff3c2342c31de1572cf26cc9aa1457b0325?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/251a6eeab40f7b6d09d05d28b9b8aff3c2342c31de1572cf26cc9aa1457b0325?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                    className="img-10"
                  />
                  <div className="silver-necklace">Silver Necklace</div>
                  <div className="120-55">$120.55</div>
                  <div className="shop-div-35">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/aac4416b685761e98160aae9d120393cd34d4a2416d8da23dcd2cef8471ae57f?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-11"
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/522e470071ff67f6b2c1617f12567da7dc1826c9f34bc60d4fe69ca6e2ffb526?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-12"
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/8a90eb01c19220fb8cd48b709d081cd562ac46bfa1f9abc2bd28a88dc6771b31?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-13"
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/c2214ebfbdf5be8f2fc3aff321290d93da4f353644b5f141b9252771d9f5680d?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-14"
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/1671dbb64d0ebbd77cce76c6a406f4dc8130a5857e2252579ce42f9bd4cb4d40?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-15"
                    />
                  </div>
                  <div className="add-to-cart">Add to Cart</div>
                </div>
                <div className="shop-div-36">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/a06f6d1d3a308405493a96d878689d6751ad6d1a51bdfc4a63fc7171cfbce434?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/a06f6d1d3a308405493a96d878689d6751ad6d1a51bdfc4a63fc7171cfbce434?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a06f6d1d3a308405493a96d878689d6751ad6d1a51bdfc4a63fc7171cfbce434?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/a06f6d1d3a308405493a96d878689d6751ad6d1a51bdfc4a63fc7171cfbce434?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/a06f6d1d3a308405493a96d878689d6751ad6d1a51bdfc4a63fc7171cfbce434?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a06f6d1d3a308405493a96d878689d6751ad6d1a51bdfc4a63fc7171cfbce434?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/a06f6d1d3a308405493a96d878689d6751ad6d1a51bdfc4a63fc7171cfbce434?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/a06f6d1d3a308405493a96d878689d6751ad6d1a51bdfc4a63fc7171cfbce434?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                    className="img-16"
                  />
                  <div className="sterling-silver-bracelet">
                    Sterling Silver Bracelet
                  </div>
                  <div className="120-55">$120.55</div>
                  <div className="shop-div-37">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/c8e0cce08597d0fd6595a5be33ce0d8dc32ccb5f139b0a4dd871d0d2713247b6?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-17"
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/f3f4678df5eb158766c0207d67ce31e5166b62db9bc14314bf7ba6f834fec075?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-18"
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/b4db872f6e24b4d69e7ce64d013f2da3e554882252061afc4ad14b8463a259db?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-19"
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/b756a2d9e4df78c714aa3d15ef2465f5503c2dcce38b28405ebf39b1e0ade8a4?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-20"
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/d93bc0fbccf84dd208c3a3b232a14f13fb8aa30bf32c7014907f02b8f4ad7796?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-21"
                    />
                  </div>
                  <div className="add-to-cart">Add to Cart</div>
                </div>
                <div className="shop-div-38">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/22a943c69dc0aa6cec1b3b41c2e1a214670085ddf1048288a1dd5bf89b31643c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/22a943c69dc0aa6cec1b3b41c2e1a214670085ddf1048288a1dd5bf89b31643c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/22a943c69dc0aa6cec1b3b41c2e1a214670085ddf1048288a1dd5bf89b31643c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/22a943c69dc0aa6cec1b3b41c2e1a214670085ddf1048288a1dd5bf89b31643c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/22a943c69dc0aa6cec1b3b41c2e1a214670085ddf1048288a1dd5bf89b31643c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/22a943c69dc0aa6cec1b3b41c2e1a214670085ddf1048288a1dd5bf89b31643c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/22a943c69dc0aa6cec1b3b41c2e1a214670085ddf1048288a1dd5bf89b31643c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/22a943c69dc0aa6cec1b3b41c2e1a214670085ddf1048288a1dd5bf89b31643c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                    className="img-22"
                  />
                  <div className="silver-stud-earring">Silver Stud Earring</div>
                  <div className="120-55">$120.55</div>
                  <div className="shop-div-39">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/17a805db6959308a93fc43bf49d8e463f2e398af5de232527dd2ad3810d2f7c6?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-23"
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/da83937b268710f1bbbc252f82b6e5ff249d52273daddc1702d951a749d1c9ad?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-24"
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/0f086470d9e944e620695382e9b56b332ff0b51e60db9ee21f35f823503b0618?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-25"
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/1dd4c55d08dea7677191640cbbbef43ff60bc18d416cf34299b131601a4d2317?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-26"
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/29dc2c3d536ab7d70c6d1ed3495e0d0cec0114a056571e256f7a082ed3d365bb?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-27"
                    />
                  </div>
                  <div className="add-to-cart">Add to Cart</div>
                </div>
              </div>
              <div className="shop-div-40">
                <div className="shop-div-41">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/21bfd4eab2f9020f77705ad8782bc2284ecd48c2bcefbc9242980c3e74a11b2c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/21bfd4eab2f9020f77705ad8782bc2284ecd48c2bcefbc9242980c3e74a11b2c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/21bfd4eab2f9020f77705ad8782bc2284ecd48c2bcefbc9242980c3e74a11b2c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/21bfd4eab2f9020f77705ad8782bc2284ecd48c2bcefbc9242980c3e74a11b2c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/21bfd4eab2f9020f77705ad8782bc2284ecd48c2bcefbc9242980c3e74a11b2c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/21bfd4eab2f9020f77705ad8782bc2284ecd48c2bcefbc9242980c3e74a11b2c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/21bfd4eab2f9020f77705ad8782bc2284ecd48c2bcefbc9242980c3e74a11b2c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/21bfd4eab2f9020f77705ad8782bc2284ecd48c2bcefbc9242980c3e74a11b2c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                    className="img-28"
                  />
                  <div className="silver-necklace">Silver Necklace</div>
                  <div className="120-55">$120.55</div>
                  <div className="shop-div-42">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/52d22cdd5347e9dd7b2dd97e72a348d6d5fb889293099dea2e90eb5411a9267e?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-29"
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/13dd9131d5144d2ec3b94eefb3f70e0bfd08e322f5a7410b8c47730b9df5e86a?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-30"
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/5432a4a73b3198b545512ad115e34ada15ba01c2e0ec1009f83eb2a23e36310e?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-31"
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/403e49860a8b5f9a0265d90b705e47dd31d888ac94495c16c5b55eefb040588b?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-32"
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/cec1e12b9d9a66594dd4f027cb0c883acc76aa11d47d445b72f092df13ed02ca?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-33"
                    />
                  </div>
                  <div className="add-to-cart">Add to Cart</div>
                </div>
                <div className="shop-div-43">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/251a6eeab40f7b6d09d05d28b9b8aff3c2342c31de1572cf26cc9aa1457b0325?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/251a6eeab40f7b6d09d05d28b9b8aff3c2342c31de1572cf26cc9aa1457b0325?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/251a6eeab40f7b6d09d05d28b9b8aff3c2342c31de1572cf26cc9aa1457b0325?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/251a6eeab40f7b6d09d05d28b9b8aff3c2342c31de1572cf26cc9aa1457b0325?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/251a6eeab40f7b6d09d05d28b9b8aff3c2342c31de1572cf26cc9aa1457b0325?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/251a6eeab40f7b6d09d05d28b9b8aff3c2342c31de1572cf26cc9aa1457b0325?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/251a6eeab40f7b6d09d05d28b9b8aff3c2342c31de1572cf26cc9aa1457b0325?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/251a6eeab40f7b6d09d05d28b9b8aff3c2342c31de1572cf26cc9aa1457b0325?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                    className="img-34"
                  />
                  <div className="silver-necklace">Silver Necklace</div>
                  <div className="120-55">$120.55</div>
                  <div className="shop-div-44">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/aac4416b685761e98160aae9d120393cd34d4a2416d8da23dcd2cef8471ae57f?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-35"
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/522e470071ff67f6b2c1617f12567da7dc1826c9f34bc60d4fe69ca6e2ffb526?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-36"
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/7ad2b1bc0bd4d484cd845279b56af887df92e396fba7f6dad0b9deed874abd31?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-37"
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/1870e118324112342b9202e92f7c12ea2754f71c58b83d3308cacec251d4a003?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-38"
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/d1d9861159165bd5d724f66cf02f27f7c73770bb902e453d1a3927da963d32b5?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-39"
                    />
                  </div>
                  <div className="add-to-cart">Add to Cart</div>
                </div>
                <div className="shop-div-45">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/a06f6d1d3a308405493a96d878689d6751ad6d1a51bdfc4a63fc7171cfbce434?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/a06f6d1d3a308405493a96d878689d6751ad6d1a51bdfc4a63fc7171cfbce434?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a06f6d1d3a308405493a96d878689d6751ad6d1a51bdfc4a63fc7171cfbce434?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/a06f6d1d3a308405493a96d878689d6751ad6d1a51bdfc4a63fc7171cfbce434?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/a06f6d1d3a308405493a96d878689d6751ad6d1a51bdfc4a63fc7171cfbce434?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a06f6d1d3a308405493a96d878689d6751ad6d1a51bdfc4a63fc7171cfbce434?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/a06f6d1d3a308405493a96d878689d6751ad6d1a51bdfc4a63fc7171cfbce434?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/a06f6d1d3a308405493a96d878689d6751ad6d1a51bdfc4a63fc7171cfbce434?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                    className="img-40"
                  />
                  <div className="sterling-silver-bracelet">
                    Sterling Silver Bracelet
                  </div>
                  <div className="120-55">$120.55</div>
                  <div className="shop-div-46">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/c8e0cce08597d0fd6595a5be33ce0d8dc32ccb5f139b0a4dd871d0d2713247b6?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-41"
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/2d581454d6b54023995a5d23246457f520b4435f2585d2c5e6cc8d3aa72d1e86?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-42"
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/708944f8f7ccb4895317be6b0fa1108f7d7222e1db84953d10acb66bbc863f7e?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-43"
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/b756a2d9e4df78c714aa3d15ef2465f5503c2dcce38b28405ebf39b1e0ade8a4?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-44"
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/d93bc0fbccf84dd208c3a3b232a14f13fb8aa30bf32c7014907f02b8f4ad7796?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-45"
                    />
                  </div>
                  <div className="add-to-cart">Add to Cart</div>
                </div>
                <div className="shop-div-47">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/22a943c69dc0aa6cec1b3b41c2e1a214670085ddf1048288a1dd5bf89b31643c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/22a943c69dc0aa6cec1b3b41c2e1a214670085ddf1048288a1dd5bf89b31643c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/22a943c69dc0aa6cec1b3b41c2e1a214670085ddf1048288a1dd5bf89b31643c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/22a943c69dc0aa6cec1b3b41c2e1a214670085ddf1048288a1dd5bf89b31643c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/22a943c69dc0aa6cec1b3b41c2e1a214670085ddf1048288a1dd5bf89b31643c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/22a943c69dc0aa6cec1b3b41c2e1a214670085ddf1048288a1dd5bf89b31643c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/22a943c69dc0aa6cec1b3b41c2e1a214670085ddf1048288a1dd5bf89b31643c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/22a943c69dc0aa6cec1b3b41c2e1a214670085ddf1048288a1dd5bf89b31643c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                    className="img-46"
                  />
                  <div className="silver-stud-earring">Silver Stud Earring</div>
                  <div className="120-55">$120.55</div>
                  <div className="shop-div-48">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/3f4b6814a8edfdf73a61181df578d50d4809d4f925abe2f68367f3ac4fe4a459?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-47"
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/f0114ccc9500878bcf23d25e55a1c393e557856eeac86597948588560b63ab8a?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-48"
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/0f086470d9e944e620695382e9b56b332ff0b51e60db9ee21f35f823503b0618?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-49"
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/1dd4c55d08dea7677191640cbbbef43ff60bc18d416cf34299b131601a4d2317?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-50"
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/b93e7f88fe2b961cdf193ce11f16d9eee2e99993a42814bd29e4d766e9365cd9?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                      className="img-51"
                    />
                  </div>
                  <div className="add-to-cart">Add to Cart</div>
                </div>
              </div>
            </div>
          </div>
          <div className="shop-div-49">
            <div className="1">1 </div>
            <div>2</div>
            <div>3 </div>
            <div>4 </div>
          </div>
          <div className="silver-products">Silver Products </div>
        </div>
        <div className="shop-div-50" />
        <div className="shop-div-51">
          <div className="shop-div-52">
            <div className="column-3">
              <div className="shop-div-53">
                <div className="shop-div-54">
                  <div className="sustainable-silver-secure-growth-and-a-greener-future">
                    Sustainable Silver: Secure Growth and a Greener Future
                  </div>
                  <div className="silver-offers-stability-and-growth-with-markets-projected-to-rise-significantly-at-argentum-our-sustainably-sourced-silver-aligns-your-investment-with-environmental-impact-supporting-both-your-goals-and-the-planet">
                    Silver offers stability and growth, with markets projected
                    to rise significantly. At Argentum, our sustainably sourced
                    silver aligns your investment with environmental impact,
                    supporting both your goals and the planet.
                  </div>
                  <div className="invest-now">Invest Now</div>
                  <div className="shop-div-55">
                    <div className="shop-div-56">
                      <div>10 grams</div>
                      <div className="20-00">$20.00</div>
                    </div>
                    <div className="shop-div-57">
                      <div>20 grams</div>
                      <div className="39-99">$39.99</div>
                    </div>
                    <div className="shop-div-58">
                      <div>50 grams</div>
                      <div className="99-99">$99.99</div>
                    </div>
                    <div className="specify">Specify</div>
                  </div>
                </div>
                <div className="shop-div-59">
                  <div className="buy-now">Buy Now</div>
                </div>
              </div>
            </div>
            <div className="column-4">
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/5fd6ec0801dee4d75702cb97837843f1abd75065e7be61385ad6751bcc459607?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/5fd6ec0801dee4d75702cb97837843f1abd75065e7be61385ad6751bcc459607?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5fd6ec0801dee4d75702cb97837843f1abd75065e7be61385ad6751bcc459607?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/5fd6ec0801dee4d75702cb97837843f1abd75065e7be61385ad6751bcc459607?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/5fd6ec0801dee4d75702cb97837843f1abd75065e7be61385ad6751bcc459607?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5fd6ec0801dee4d75702cb97837843f1abd75065e7be61385ad6751bcc459607?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/5fd6ec0801dee4d75702cb97837843f1abd75065e7be61385ad6751bcc459607?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/5fd6ec0801dee4d75702cb97837843f1abd75065e7be61385ad6751bcc459607?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                className="img-53"
              />
            </div>
          </div>
        </div>
        <div className="jewelry-customization">Jewelry Customization</div>
        <div className="shop-div-60" />
        <div className="shop-div-61">
          <div className="shop-div-62">
            <div className="column-5">
              <div className="shop-div-63">
                <div className="transform-your-imagination-into-jewelry">
                  Transform Your Imagination into Jewelry
                </div>
                <div className="share-the-story-or-specifics-of-your-design-and-well-bring-it-to-life">
                  Share the story or specifics of your design, and we’ll bring
                  it to life.
                </div>
                <div className="jewelry-description-example">
                  Jewelry description example
                </div>
                <div className="shop-div-64">
                  <div className="shop-div-65">
                    <div className="upload-your-sketch">Upload Your Sketch</div>
                  </div>
                  <div className="shop-div-66">
                    <div className="send-description-only">
                      Send Description Only
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column-6">
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c2e5b27bdfc75516eb4414733a87ff5883db00e4e494e947c083d5c1057ceb0d?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c2e5b27bdfc75516eb4414733a87ff5883db00e4e494e947c083d5c1057ceb0d?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c2e5b27bdfc75516eb4414733a87ff5883db00e4e494e947c083d5c1057ceb0d?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c2e5b27bdfc75516eb4414733a87ff5883db00e4e494e947c083d5c1057ceb0d?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c2e5b27bdfc75516eb4414733a87ff5883db00e4e494e947c083d5c1057ceb0d?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c2e5b27bdfc75516eb4414733a87ff5883db00e4e494e947c083d5c1057ceb0d?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c2e5b27bdfc75516eb4414733a87ff5883db00e4e494e947c083d5c1057ceb0d?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c2e5b27bdfc75516eb4414733a87ff5883db00e4e494e947c083d5c1057ceb0d?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                className="img-56"
              />
            </div>
          </div>
        </div>
        <div className="shop-div-67">
          <div className="shop-div-68">
            <div className="column-7">
              <div className="shop-div-69">
                <div className="shop-div-70" />
                <div className="home">Home</div>
                <div className="shop-3">Shop</div>
                <div className="about">About</div>
                <div className="community-2">Community</div>
              </div>
            </div>
            <div className="column-8">
              <div className="shop-div-71">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/fcd366b6ea741540cdbdb6a230ab0459a8487a50d531a7a6d840b2e828d2bbe3?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                  className="img-57"
                />
                <div className="argentum">ARGENTUM</div>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/e6fdac41435667c1e8acf5467dd79aeedec8b449fb3598f7df6756c0e0736b9a?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                  className="img-58"
                />
                <div className="shop-div-72" />
                <div className="weekly-newsletter">Weekly Newsletter</div>
                <div className="shop-div-73">
                  <div className="email-address">Email Address</div>
                  <div className="subscribe">Subscribe</div>
                </div>
              </div>
            </div>
            <div className="column-9">
              <div className="shop-div-74">
                <div className="shop-div-75" />
                <div className="info-argentumco-com">info@argentumco.com</div>
                <div className="961-78901035">+961 78901035</div>
                <div className="961-71669247">+961 71669247</div>
                <div className="beirut-lebanon">Beirut - Lebanon </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .1 {
          color: rgba(192, 192, 192, 1);
        }
        .shop {
          background: var(
            --Linear-Background,
            linear-gradient(117deg, #1c1c1c 0%, rgba(192, 192, 192, 0.5) 100%)
          );
          background-color: var(
            --Linear-Background,
            linear-gradient(117deg, #1c1c1c 0%, rgba(192, 192, 192, 0.5) 100%)
          );
          display: flex;
          flex-direction: column;
          overflow: hidden;
          align-items: center;
        }
        .div {
          background-color: rgba(28, 28, 28, 1);
          box-shadow: 0px 6px 8px rgba(192, 192, 192, 0.5);
          align-self: stretch;
          width: 100%;
          overflow: hidden;
          padding: 19px 51px;
        }
        @media (max-width: 991px) {
          .div {
            max-width: 100%;
            padding: 0 20px;
          }
        }
        .shop-div-2 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .shop-div-2 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 83%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column {
            width: 100%;
          }
        }
        .shop-div-3 {
          display: flex;
          width: 100%;
          align-items: center;
          gap: 40px 100px;
          color: rgba(192, 192, 192, 0.6);
          justify-content: space-between;
          flex-wrap: wrap;
          font: 700 20px Raleway, sans-serif;
        }
        @media (max-width: 991px) {
          .shop-div-3 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .ag-logo-2 {
          align-self: stretch;
          display: flex;
          width: 93px;
          height: 63px;
          margin: auto 0;
        }
        .shop-div-4 {
          align-self: stretch;
          display: flex;
          min-width: 240px;
          align-items: center;
          gap: 40px 70px;
          justify-content: start;
          flex-wrap: wrap;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .shop-div-4 {
            max-width: 100%;
          }
        }
        .home-page {
          align-self: stretch;
          margin: auto 0;
        }
        .shop-2 {
          color: rgba(192, 192, 192, 1);
          align-self: stretch;
          margin: auto 0;
        }
        .community {
          align-self: stretch;
          margin: auto 0;
        }
        .about-us {
          align-self: stretch;
          margin: auto 0;
        }
        .column-2 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 17%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-2 {
            width: 100%;
          }
        }
        .shop-div-5 {
          display: flex;
          width: 100%;
          gap: 15px;
          align-self: stretch;
          color: rgba(28, 28, 28, 1);
          margin: auto 0;
          font: 700 15px Poppins, sans-serif;
        }
        @media (max-width: 991px) {
          .shop-div-5 {
            margin-top: 40px;
          }
        }
        .img {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 49px;
          border-radius: 99px;
        }
        .log-in {
          align-self: stretch;
          border-radius: 20px;
          gap: 10px;
          overflow: hidden;
          padding: 13px 40px;
        }
        @media (max-width: 991px) {
          .log-in {
            padding: 0 20px;
          }
        }
        .shop-div-6 {
          display: flex;
          flex-direction: column;
          align-self: stretch;
          position: relative;
          min-height: 304px;
          width: 100%;
          font-family: Raleway, sans-serif;
          color: rgba(255, 255, 255, 1);
        }
        @media (max-width: 991px) {
          .shop-div-6 {
            max-width: 100%;
          }
        }
        .img-2 {
          position: absolute;
          inset: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
        .shop-div-7 {
          position: relative;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          width: 100%;
          flex-direction: column;
          overflow: hidden;
          align-items: center;
          padding: 86px 80px 51px;
        }
        @media (max-width: 991px) {
          .shop-div-7 {
            max-width: 100%;
            padding: 0 20px;
          }
        }
        .shop-div-8 {
          display: flex;
          width: 611px;
          max-width: 100%;
          flex-direction: column;
        }
        .elegance-in-every-piece {
          font-size: 50px;
          font-weight: 700;
          letter-spacing: 2px;
        }
        @media (max-width: 991px) {
          .elegance-in-every-piece {
            max-width: 100%;
            font-size: 40px;
          }
        }
        .explore-argentums-exclusive-silver-jewelry-collection-crafted-with-precision-and-sustainability-in-mind-each-piece-reflects-our-commitment-to-luxury-and-eco-conscious-values-blending-timeless-design-with-a-polished-finish {
          font-size: 20px;
          font-weight: 600;
          text-align: center;
          margin-top: 16px;
        }
        @media (max-width: 991px) {
          .explore-argentums-exclusive-silver-jewelry-collection-crafted-with-precision-and-sustainability-in-mind-each-piece-reflects-our-commitment-to-luxury-and-eco-conscious-values-blending-timeless-design-with-a-polished-finish {
            max-width: 100%;
            margin-right: 4px;
          }
        }
        .our-products {
          margin-top: 50px;
          font: 800 30px Raleway, sans-serif;
          color: white;
        }
        @media (max-width: 991px) {
          .our-products {
            margin-top: 40px;
          }
        }
        .shop-div-9 {
          display: flex;
          margin-top: 20px;
          flex-direction: column;
          padding: 0 50px;
        }
        @media (max-width: 991px) {
          .shop-div-9 {
            max-width: 100%;
            padding: 0 20px;
          }
        }
        .shop-div-10 {
          box-shadow: 0px 0px 4px rgba(192, 192, 192, 1);
          width: 1340px;
          max-width: 100%;
          height: 0px;
        }
        @media (max-width: 991px) {
          .shop-div-10 {
            margin-right: 3px;
          }
        }
        .shop-div-11 {
          align-self: end;
          display: flex;
          margin-top: 20px;
          width: 100%;
          max-width: 1150px;
          gap: 20px;
          font-size: 15px;
          color: rgba(192, 192, 192, 1);
          font-weight: 700;
          flex-wrap: wrap;
          justify-content: space-between;
        }
        @media (max-width: 991px) {
          .shop-div-11 {
            max-width: 100%;
            margin-right: 3px;
          }
        }
        .showing-8-of-50-products {
          font-family: Raleway, sans-serif;
          letter-spacing: 0.6px;
          margin: auto 0;
        }
        .shop-div-12 {
          display: flex;
          gap: 8px;
          font-family: Roboto, sans-serif;
        }
        .sort-by {
          flex-grow: 1;
          margin: auto 0;
        }
        .shop-div-13 {
          border-radius: 10px;
          background-color: rgba(28, 28, 28, 1);
          display: flex;
          flex-direction: column;
          overflow: hidden;
          padding: 3px 0;
          border: 1px solid rgba(192, 192, 192, 1);
        }
        .shop-div-14 {
          align-self: center;
          display: flex;
          gap: 40px 49px;
        }
        .best-selling {
          margin: auto 0;
        }
        .img-3 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 21px;
        }
        .shop-div-15 {
          height: 1px;
          border: 1px solid rgba(192, 192, 192, 1);
        }
        .shop-div-16 {
          display: flex;
          margin-top: 30px;
          gap: 40px 63px;
          flex-wrap: wrap;
        }
        @media (max-width: 991px) {
          .shop-div-16 {
            max-width: 100%;
          }
        }
        .shop-div-17 {
          align-self: start;
          display: flex;
          flex-direction: column;
          color: rgba(192, 192, 192, 1);
          white-space: nowrap;
          justify-content: start;
          font: 600 15px Roboto, sans-serif;
        }
        @media (max-width: 991px) {
          .shop-div-17 {
            white-space: initial;
          }
        }
        .shop-div-18 {
          display: flex;
          width: 100%;
          max-width: 125px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .shop-div-18 {
            white-space: initial;
          }
        }
        .shop-div-19 {
          display: flex;
          gap: 20px;
          justify-content: space-between;
        }
        @media (max-width: 991px) {
          .shop-div-19 {
            margin-right: 4px;
            white-space: initial;
          }
        }
        . {
          text-align: center;
        }
        .shop-div-20 {
          margin-top: 19px;
          height: 1px;
          border: 1px solid rgba(192, 192, 192, 1);
        }
        .shop-div-21 {
          display: flex;
          margin-top: 20px;
          width: 100%;
          max-width: 125px;
          flex-direction: column;
          text-align: center;
        }
        @media (max-width: 991px) {
          .shop-div-21 {
            white-space: initial;
          }
        }
        .shop-div-22 {
          display: flex;
          gap: 20px;
          justify-content: space-between;
        }
        @media (max-width: 991px) {
          .shop-div-22 {
            margin-right: 4px;
            white-space: initial;
          }
        }
        .shop-div-23 {
          margin-top: 19px;
          height: 1px;
          border: 1px solid rgba(192, 192, 192, 1);
        }
        .shop-div-24 {
          display: flex;
          margin-top: 20px;
          width: 100%;
          max-width: 125px;
          flex-direction: column;
          text-align: center;
        }
        @media (max-width: 991px) {
          .shop-div-24 {
            white-space: initial;
          }
        }
        .shop-div-25 {
          display: flex;
          gap: 20px;
          justify-content: space-between;
        }
        @media (max-width: 991px) {
          .shop-div-25 {
            margin-right: 4px;
            white-space: initial;
          }
        }
        .shop-div-26 {
          margin-top: 19px;
          height: 1px;
          border: 1px solid rgba(192, 192, 192, 1);
        }
        .shop-div-27 {
          display: flex;
          margin-top: 20px;
          width: 100%;
          max-width: 125px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .shop-div-27 {
            white-space: initial;
          }
        }
        .shop-div-28 {
          display: flex;
          gap: 20px;
          justify-content: space-between;
        }
        @media (max-width: 991px) {
          .shop-div-28 {
            margin-right: 4px;
            white-space: initial;
          }
        }
        .shop-div-29 {
          margin-top: 19px;
          height: 1px;
          border: 1px solid rgba(192, 192, 192, 1);
        }
        .shop-div-30 {
          display: flex;
          flex-direction: column;
          justify-content: start;
          flex-grow: 1;
          flex-basis: 0;
          width: fit-content;
        }
        @media (max-width: 991px) {
          .shop-div-30 {
            max-width: 100%;
          }
        }
        .shop-div-31 {
          overflow-x: auto;
          display: flex;
          width: 100%;
          align-items: center;
          gap: 40px 41px;
          justify-content: start;
          flex-wrap: wrap;
        }
        @media (max-width: 991px) {
          .shop-div-31 {
            max-width: 100%;
          }
        }
        .shop-div-32 {
          border-radius: 13px;
          box-shadow: -5px 7px 9px rgba(192, 192, 192, 0.25);
          align-self: stretch;
          display: flex;
          min-width: 240px;
          padding-bottom: 20px;
          flex-direction: column;
          overflow: hidden;
          align-items: center;
          flex-grow: 1;
          width: 206px;
          margin: auto 0;
          background-color: white;
        }
        .img-4 {
          aspect-ratio: 1.18;
          object-fit: contain;
          object-position: center;
          width: 100%;
          align-self: stretch;
        }
        .silver-necklace {
          color: rgba(28, 28, 28, 1);
          margin-top: 8px;
          font: 700 17px Lato, sans-serif;
        }
        .120-55 {
          color: rgba(0, 0, 0, 1);
          text-align: center;
          margin-top: 8px;
          font: 700 17px Lato, sans-serif;
        }
        .shop-div-33 {
          display: flex;
          margin-top: 13px;
          align-items: center;
          gap: 4px;
          justify-content: start;
        }
        .img-5 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .img-6 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .img-7 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .img-8 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .img-9 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .add-to-cart {
          border-radius: 17px;
          background-color: rgba(28, 28, 28, 1);
          margin-top: 17px;
          gap: 9px;
          overflow: hidden;
          color: rgba(192, 192, 192, 1);
          padding: 11px 34px;
          font: 700 13px Poppins, sans-serif;
        }
        @media (max-width: 991px) {
          .add-to-cart {
            padding: 0 20px;
          }
        }
        .shop-div-34 {
          border-radius: 13px;
          box-shadow: -5px 7px 9px rgba(192, 192, 192, 0.25);
          align-self: stretch;
          display: flex;
          min-width: 240px;
          padding-bottom: 20px;
          flex-direction: column;
          overflow: hidden;
          align-items: center;
          flex-grow: 1;
          width: 206px;
          margin: auto 0;
          background-color: white;
        }
        .img-10 {
          aspect-ratio: 1.19;
          object-fit: contain;
          object-position: center;
          width: 100%;
          align-self: stretch;
        }
        .shop-div-35 {
          display: flex;
          margin-top: 13px;
          align-items: center;
          gap: 4px;
          justify-content: start;
        }
        .img-11 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .img-12 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .img-13 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .img-14 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .img-15 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .shop-div-36 {
          border-radius: 13px;
          box-shadow: -5px 7px 9px rgba(192, 192, 192, 0.25);
          align-self: stretch;
          display: flex;
          min-width: 240px;
          padding-bottom: 20px;
          flex-direction: column;
          overflow: hidden;
          align-items: center;
          flex-grow: 1;
          width: 206px;
          margin: auto 0;
          background-color: white;
        }
        .img-16 {
          aspect-ratio: 1.18;
          object-fit: contain;
          object-position: center;
          width: 100%;
          align-self: stretch;
        }
        .sterling-silver-bracelet {
          color: rgba(28, 28, 28, 1);
          margin-top: 8px;
          font: 700 17px Lato, sans-serif;
        }
        .shop-div-37 {
          display: flex;
          margin-top: 13px;
          align-items: center;
          gap: 4px;
          justify-content: start;
        }
        .img-17 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .img-18 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .img-19 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .img-20 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .img-21 {
          aspect-ratio: 0.95;
          object-fit: contain;
          object-position: center;
          width: 18px;
          align-self: stretch;
          margin: auto 0;
        }
        .shop-div-38 {
          border-radius: 13px;
          box-shadow: -5px 7px 9px rgba(192, 192, 192, 0.25);
          align-self: stretch;
          display: flex;
          min-width: 240px;
          padding-bottom: 20px;
          flex-direction: column;
          overflow: hidden;
          align-items: center;
          flex-grow: 1;
          width: 206px;
          margin: auto 0;
          background-color: white;
        }
        .img-22 {
          aspect-ratio: 1.18;
          object-fit: contain;
          object-position: center;
          width: 100%;
          align-self: stretch;
        }
        .silver-stud-earring {
          color: rgba(28, 28, 28, 1);
          margin-top: 8px;
          font: 700 17px Lato, sans-serif;
        }
        .shop-div-39 {
          display: flex;
          margin-top: 13px;
          align-items: center;
          gap: 4px;
          justify-content: start;
        }
        .img-23 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .img-24 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .img-25 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .img-26 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .img-27 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .shop-div-40 {
          overflow-x: auto;
          display: flex;
          margin-top: 30px;
          width: 100%;
          align-items: center;
          gap: 40px 41px;
          justify-content: start;
          flex-wrap: wrap;
        }
        @media (max-width: 991px) {
          .shop-div-40 {
            max-width: 100%;
          }
        }
        .shop-div-41 {
          border-radius: 13px;
          box-shadow: -5px 7px 9px rgba(192, 192, 192, 0.25);
          align-self: stretch;
          display: flex;
          min-width: 240px;
          padding-bottom: 20px;
          flex-direction: column;
          overflow: hidden;
          align-items: center;
          flex-grow: 1;
          width: 206px;
          margin: auto 0;
          background-color: white;
        }
        .img-28 {
          aspect-ratio: 1.18;
          object-fit: contain;
          object-position: center;
          width: 100%;
          align-self: stretch;
        }
        .shop-div-42 {
          display: flex;
          margin-top: 13px;
          align-items: center;
          gap: 4px;
          justify-content: start;
        }
        .img-29 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .img-30 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .img-31 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .img-32 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .img-33 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .shop-div-43 {
          border-radius: 13px;
          box-shadow: -5px 7px 9px rgba(192, 192, 192, 0.25);
          align-self: stretch;
          display: flex;
          min-width: 240px;
          padding-bottom: 20px;
          flex-direction: column;
          overflow: hidden;
          align-items: center;
          flex-grow: 1;
          width: 206px;
          margin: auto 0;
          background-color: white;
        }
        .img-34 {
          aspect-ratio: 1.19;
          object-fit: contain;
          object-position: center;
          width: 100%;
          align-self: stretch;
        }
        .shop-div-44 {
          display: flex;
          margin-top: 13px;
          align-items: center;
          gap: 4px;
          justify-content: start;
        }
        .img-35 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .img-36 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .img-37 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .img-38 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .img-39 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .shop-div-45 {
          border-radius: 13px;
          box-shadow: -5px 7px 9px rgba(192, 192, 192, 0.25);
          align-self: stretch;
          display: flex;
          min-width: 240px;
          padding-bottom: 20px;
          flex-direction: column;
          overflow: hidden;
          align-items: center;
          flex-grow: 1;
          width: 206px;
          margin: auto 0;
          background-color: white;
        }
        .img-40 {
          aspect-ratio: 1.18;
          object-fit: contain;
          object-position: center;
          width: 100%;
          align-self: stretch;
        }
        .shop-div-46 {
          display: flex;
          margin-top: 13px;
          align-items: center;
          gap: 4px;
          justify-content: start;
        }
        .img-41 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .img-42 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .img-43 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .img-44 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .img-45 {
          aspect-ratio: 0.95;
          object-fit: contain;
          object-position: center;
          width: 18px;
          align-self: stretch;
          margin: auto 0;
        }
        .shop-div-47 {
          border-radius: 13px;
          box-shadow: -5px 7px 9px rgba(192, 192, 192, 0.25);
          align-self: stretch;
          display: flex;
          min-width: 240px;
          padding-bottom: 20px;
          flex-direction: column;
          overflow: hidden;
          align-items: center;
          flex-grow: 1;
          width: 206px;
          margin: auto 0;
          background-color: white;
        }
        .img-46 {
          aspect-ratio: 1.18;
          object-fit: contain;
          object-position: center;
          width: 100%;
          align-self: stretch;
        }
        .shop-div-48 {
          display: flex;
          margin-top: 13px;
          align-items: center;
          gap: 4px;
          justify-content: start;
        }
        .img-47 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .img-48 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .img-49 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .img-50 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .img-51 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 19px;
          align-self: stretch;
          margin: auto 0;
        }
        .shop-div-49 {
          align-self: center;
          display: flex;
          width: 138px;
          max-width: 100%;
          gap: 20px;
          color: rgba(192, 192, 192, 0.25);
          text-align: center;
          justify-content: space-between;
          margin: 53px 0 0 97px;
          font: 600 20px Roboto, sans-serif;
        }
        @media (max-width: 991px) {
          .shop-div-49 {
            margin-top: 40px;
          }
        }
        .silver-products {
          align-self: center;
          margin-top: 50px;
          font: 800 30px Raleway, sans-serif;
          color: white;
        }
        @media (max-width: 991px) {
          .silver-products {
            margin-top: 40px;
          }
        }
        .shop-div-50 {
          box-shadow: 0px 0px 4px rgba(192, 192, 192, 1);
          margin-top: 20px;
          width: 1340px;
          max-width: 100%;
          height: 0px;
        }
        .shop-div-51 {
          margin-top: 30px;
          width: 100%;
          max-width: 1349px;
        }
        @media (max-width: 991px) {
          .shop-div-51 {
            max-width: 100%;
          }
        }
        .shop-div-52 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .shop-div-52 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-3 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-3 {
            width: 100%;
          }
        }
        .shop-div-53 {
          display: flex;
          width: 100%;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .shop-div-53 {
            max-width: 100%;
            margin-top: 33px;
          }
        }
        .shop-div-54 {
          display: flex;
          width: 100%;
          padding-left: 9px;
          flex-direction: column;
          align-items: start;
          color: rgba(192, 192, 192, 1);
          font: 800 25px Lato, sans-serif;
        }
        @media (max-width: 991px) {
          .shop-div-54 {
            max-width: 100%;
          }
        }
        .sustainable-silver-secure-growth-and-a-greener-future {
          font: 40px Raleway, sans-serif;
        }
        @media (max-width: 991px) {
          .sustainable-silver-secure-growth-and-a-greener-future {
            max-width: 100%;
          }
        }
        .silver-offers-stability-and-growth-with-markets-projected-to-rise-significantly-at-argentum-our-sustainably-sourced-silver-aligns-your-investment-with-environmental-impact-supporting-both-your-goals-and-the-planet {
          font-weight: 500;
          align-self: stretch;
          margin-top: 15px;
        }
        @media (max-width: 991px) {
          .silver-offers-stability-and-growth-with-markets-projected-to-rise-significantly-at-argentum-our-sustainably-sourced-silver-aligns-your-investment-with-environmental-impact-supporting-both-your-goals-and-the-planet {
            max-width: 100%;
          }
        }
        .invest-now {
          margin-top: 15px;
        }
        .shop-div-55 {
          display: flex;
          margin-top: 15px;
          gap: 15px;
          font-size: 15px;
          font-weight: 500;
        }
        .shop-div-56 {
          border-radius: 15px;
          display: flex;
          flex-direction: column;
          overflow: hidden;
          padding: 8px 15px;
          border: 2px solid rgba(192, 192, 192, 1);
        }
        .20-00 {
          margin-top: 5px;
        }
        @media (max-width: 991px) {
          .20-00 {
            margin-right: 6px;
          }
        }
        .shop-div-57 {
          border-radius: 15px;
          display: flex;
          flex-direction: column;
          overflow: hidden;
          padding: 8px 15px;
          border: 2px solid rgba(192, 192, 192, 1);
        }
        .39-99 {
          margin-top: 5px;
        }
        @media (max-width: 991px) {
          .39-99 {
            margin-right: 6px;
          }
        }
        .shop-div-58 {
          border-radius: 15px;
          display: flex;
          flex-direction: column;
          overflow: hidden;
          padding: 8px 15px;
          border: 2px solid rgba(192, 192, 192, 1);
        }
        .99-99 {
          margin-top: 5px;
        }
        @media (max-width: 991px) {
          .99-99 {
            margin-right: 6px;
          }
        }
        .specify {
          border-radius: 15px;
          overflow: hidden;
          color: rgba(192, 192, 192, 0.6);
          white-space: nowrap;
          padding: 19px 21px;
          border: 2px solid rgba(192, 192, 192, 1);
        }
        @media (max-width: 991px) {
          .specify {
            white-space: initial;
            padding: 0 20px;
          }
        }
        .shop-div-59 {
          display: flex;
          flex-direction: column;
          border-radius: 20px;
          position: relative;
          margin-top: 12px;
          min-height: 55px;
          width: 100%;
          gap: 10px;
          overflow: hidden;
          color: rgba(28, 28, 28, 1);
          justify-content: center;
          padding: 11px 12px 11px 11px;
          font: 700 15px Poppins, sans-serif;
        }
        .img-52 {
          position: absolute;
          inset: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
        .buy-now {
          position: relative;
          border-radius: 20px;
          background-color: rgba(192, 192, 192, 1);
          gap: 10px;
          overflow: hidden;
          margin: auto 0;
          padding: 13px 40px;
          width: fit-content;
        }
        @media (max-width: 991px) {
          .buy-now {
            padding: 0 20px;
          }
        }
        .column-4 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-4 {
            width: 100%;
          }
        }
        .img-53 {
          aspect-ratio: 1.99;
          object-fit: contain;
          object-position: center;
          width: 100%;
          background-blend-mode: linear_dodge;
        }
        @media (max-width: 991px) {
          .img-53 {
            max-width: 100%;
            margin-top: 33px;
          }
        }
        .jewelry-customization {
          margin-top: 42px;
          font: 800 30px Raleway, sans-serif;
          color: white;
        }
        @media (max-width: 991px) {
          .jewelry-customization {
            margin-top: 40px;
          }
        }
        .shop-div-60 {
          box-shadow: 0px 0px 4px rgba(192, 192, 192, 1);
          margin-top: 20px;
          width: 1340px;
          max-width: 100%;
          height: 0px;
        }
        .shop-div-61 {
          margin-top: 30px;
          width: 100%;
          max-width: 1349px;
        }
        @media (max-width: 991px) {
          .shop-div-61 {
            max-width: 100%;
          }
        }
        .shop-div-62 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .shop-div-62 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-5 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-5 {
            width: 100%;
          }
        }
        .shop-div-63 {
          display: flex;
          width: 100%;
          flex-direction: column;
          font-family: Lato, sans-serif;
          color: rgba(192, 192, 192, 1);
          font-weight: 500;
        }
        @media (max-width: 991px) {
          .shop-div-63 {
            max-width: 100%;
            margin-top: 37px;
          }
        }
        .transform-your-imagination-into-jewelry {
          font: 800 40px Raleway, sans-serif;
        }
        @media (max-width: 991px) {
          .transform-your-imagination-into-jewelry {
            max-width: 100%;
          }
        }
        .share-the-story-or-specifics-of-your-design-and-well-bring-it-to-life {
          font-size: 25px;
          margin: 15px 65px 0 0;
        }
        @media (max-width: 991px) {
          .share-the-story-or-specifics-of-your-design-and-well-bring-it-to-life {
            max-width: 100%;
            margin-right: 10px;
          }
        }
        .jewelry-description-example {
          border-radius: 15px;
          margin-top: 15px;
          overflow: hidden;
          font-size: 20px;
          color: rgba(192, 192, 192, 0.6);
          padding: 17px 16px 86px;
          border: 1px solid rgba(192, 192, 192, 1);
        }
        @media (max-width: 991px) {
          .jewelry-description-example {
            max-width: 100%;
            padding-right: 20px;
          }
        }
        .shop-div-64 {
          align-self: start;
          display: flex;
          margin-top: 7px;
          color: rgba(28, 28, 28, 1);
          flex-wrap: wrap;
          font: 700 15px Poppins, sans-serif;
        }
        .shop-div-65 {
          display: flex;
          flex-direction: column;
          border-radius: 20px;
          position: relative;
          aspect-ratio: 3.577;
          min-height: 55px;
          width: 254px;
          align-items: center;
          gap: 10px;
          overflow: hidden;
          justify-content: center;
          flex-grow: 1;
          flex-basis: auto;
          padding: 11px 12px 11px 11px;
        }
        .img-54 {
          position: absolute;
          inset: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
        .upload-your-sketch {
          position: relative;
          align-self: stretch;
          border-radius: 20px;
          background-color: rgba(192, 192, 192, 1);
          gap: 10px;
          overflow: hidden;
          margin: auto 0;
          padding: 13px 40px;
        }
        @media (max-width: 991px) {
          .upload-your-sketch {
            padding: 0 20px;
          }
        }
        .shop-div-66 {
          display: flex;
          flex-direction: column;
          border-radius: 20px;
          position: relative;
          aspect-ratio: 3.859;
          min-height: 55px;
          width: 274px;
          align-items: center;
          gap: 10px;
          overflow: hidden;
          justify-content: center;
          flex-grow: 1;
          flex-basis: auto;
          padding: 11px 13px 11px 11px;
        }
        .img-55 {
          position: absolute;
          inset: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
        .send-description-only {
          position: relative;
          border-radius: 20px;
          background-color: rgba(192, 192, 192, 1);
          gap: 10px;
          overflow: hidden;
          margin: auto 0;
          padding: 13px 40px;
        }
        @media (max-width: 991px) {
          .send-description-only {
            padding: 0 20px;
          }
        }
        .column-6 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-6 {
            width: 100%;
          }
        }
        .img-56 {
          aspect-ratio: 1.77;
          object-fit: contain;
          object-position: center;
          width: 100%;
        }
        @media (max-width: 991px) {
          .img-56 {
            max-width: 100%;
            margin-top: 37px;
          }
        }
        .shop-div-67 {
          background-color: rgba(192, 192, 192, 0.6);
          align-self: stretch;
          margin-top: 42px;
          width: 100%;
          overflow: hidden;
          padding: 20px 49px 31px;
        }
        @media (max-width: 991px) {
          .shop-div-67 {
            max-width: 100%;
            margin-top: 40px;
            padding: 0 20px;
          }
        }
        .shop-div-68 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .shop-div-68 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-7 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 36%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-7 {
            width: 100%;
          }
        }
        .shop-div-69 {
          display: flex;
          margin-top: 49px;
          flex-direction: column;
          align-items: center;
          color: rgba(228, 226, 221, 1);
          white-space: nowrap;
          font: 600 15px Raleway, sans-serif;
        }
        @media (max-width: 991px) {
          .shop-div-69 {
            margin-top: 40px;
            white-space: initial;
          }
        }
        .shop-div-70 {
          align-self: stretch;
          height: 1px;
          border: 1px solid rgba(255, 255, 255, 1);
        }
        .home {
          margin-top: 38px;
        }
        .shop-3 {
          margin-top: 15px;
        }
        .about {
          margin-top: 15px;
        }
        .community-2 {
          margin-top: 15px;
        }
        .column-8 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 27%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-8 {
            width: 100%;
          }
        }
        .shop-div-71 {
          display: flex;
          width: 100%;
          flex-direction: column;
          align-items: center;
        }
        @media (max-width: 991px) {
          .shop-div-71 {
            margin-top: 40px;
          }
        }
        .img-57 {
          aspect-ratio: 0.8;
          object-fit: contain;
          object-position: center;
          width: 47px;
        }
        .argentum {
          color: rgba(228, 226, 221, 1);
          margin-top: 10px;
          font: 400 23px FONTSPRING DEMO - Quincy CF Text, -apple-system, Roboto,
            Helvetica, sans-serif;
        }
        .img-58 {
          aspect-ratio: 8.55;
          object-fit: contain;
          object-position: center;
          width: 100%;
          align-self: stretch;
          margin-top: 55px;
        }
        @media (max-width: 991px) {
          .img-58 {
            margin-top: 40px;
          }
        }
        .shop-div-72 {
          margin-top: 40px;
          width: 1px;
          height: 38px;
          border: 1px solid rgba(255, 255, 255, 1);
        }
        .weekly-newsletter {
          color: rgba(228, 226, 221, 1);
          margin-top: 20px;
          font: 600 15px Raleway, sans-serif;
        }
        .shop-div-73 {
          border-radius: 15px;
          align-self: stretch;
          display: flex;
          margin-top: 10px;
          width: 100%;
          gap: 20px;
          overflow: hidden;
          font-size: 12px;
          justify-content: space-between;
          padding: 0 1px 0 15px;
          border: 1px solid rgba(192, 192, 192, 1);
        }
        @media (max-width: 991px) {
          .shop-div-73 {
            margin: 0 6px 0 7px;
          }
        }
        .email-address {
          color: rgba(228, 226, 221, 0.6);
          font-family: Lato, sans-serif;
          font-weight: 500;
          margin: auto 0;
        }
        .subscribe {
          align-self: stretch;
          background-color: rgba(192, 192, 192, 1);
          min-height: 33px;
          gap: 10px;
          font-family: Poppins, sans-serif;
          color: rgba(28, 28, 28, 0.7);
          font-weight: 700;
          white-space: nowrap;
          padding: 8px 20px;
        }
        @media (max-width: 991px) {
          .subscribe {
            white-space: initial;
          }
        }
        .column-9 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 36%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-9 {
            width: 100%;
          }
        }
        .shop-div-74 {
          display: flex;
          margin-top: 49px;
          flex-direction: column;
          align-items: center;
          color: rgba(228, 226, 221, 1);
          font: 600 15px Raleway, sans-serif;
        }
        @media (max-width: 991px) {
          .shop-div-74 {
            margin-top: 40px;
          }
        }
        .shop-div-75 {
          align-self: stretch;
          height: 1px;
          border: 1px solid rgba(255, 255, 255, 1);
        }
        .info-argentumco-com {
          margin-top: 38px;
        }
        .961-78901035 {
          margin-top: 15px;
        }
        .961-71669247 {
          margin-top: 15px;
        }
        .beirut-lebanon {
          margin-top: 15px;
        }
      `}</style>
    </>
  );
}
export default Shop;
