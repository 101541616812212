import * as React from "react";
import Pin from "./pin";
import Events from "./ComEvents";
import Connect from "./ComConnect";
import Navbar from "./nav";

function Community() {
  return (
    <>
      {" "}
      <Navbar />
      <div className="community-page">
        <div className="community-div-6">
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/e1e0ebf9adbd3079087aa3351aa117d7ae0dc0ab329e89d6729f017050bcb11e?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/e1e0ebf9adbd3079087aa3351aa117d7ae0dc0ab329e89d6729f017050bcb11e?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e1e0ebf9adbd3079087aa3351aa117d7ae0dc0ab329e89d6729f017050bcb11e?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/e1e0ebf9adbd3079087aa3351aa117d7ae0dc0ab329e89d6729f017050bcb11e?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/e1e0ebf9adbd3079087aa3351aa117d7ae0dc0ab329e89d6729f017050bcb11e?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e1e0ebf9adbd3079087aa3351aa117d7ae0dc0ab329e89d6729f017050bcb11e?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/e1e0ebf9adbd3079087aa3351aa117d7ae0dc0ab329e89d6729f017050bcb11e?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/e1e0ebf9adbd3079087aa3351aa117d7ae0dc0ab329e89d6729f017050bcb11e?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
            className="community-img-2"
          />
          <div className="community-div-7">
            <div className="community-div-8">
              <div className="join-our-community">Join Our Community</div>
              <div className="be-a-part-of-our-mission-to-create-a-sustainable-future-while-enjoying-exclusive-benefits">
                Be a part of our mission to create a sustainable future
                <br />
                while enjoying exclusive benefits!
              </div>
              <div className="join-now">Join Now</div>
            </div>
          </div>
        </div>
        <div className="community-div-9">
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/d47619a8e172b1806a382e2df57c4b59284e21928a982ae1e24625e8ffb92754?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/d47619a8e172b1806a382e2df57c4b59284e21928a982ae1e24625e8ffb92754?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d47619a8e172b1806a382e2df57c4b59284e21928a982ae1e24625e8ffb92754?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/d47619a8e172b1806a382e2df57c4b59284e21928a982ae1e24625e8ffb92754?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/d47619a8e172b1806a382e2df57c4b59284e21928a982ae1e24625e8ffb92754?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d47619a8e172b1806a382e2df57c4b59284e21928a982ae1e24625e8ffb92754?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/d47619a8e172b1806a382e2df57c4b59284e21928a982ae1e24625e8ffb92754?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/d47619a8e172b1806a382e2df57c4b59284e21928a982ae1e24625e8ffb92754?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
            className="community-img-3"
          />
          <div className="community-div-10">
            <div className="community-div-11">
              <div className="the-environmental-pin">The Environmental Pin</div>
              <Pin />
            </div>
            <div className="community-div-13">
              <div className="upcoming-event">Upcoming Event</div>
              <Events />
            </div>
            <div className="community-div-15">
              <div className="connect-with-other-members">
                Connect with Other Members
              </div>
              <Connect />{" "}
            </div>
            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/9c0f1ca0cb0c77f93c4d84f3dc79a76d2e9dc7dab062e20c148e5c939678e391?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/9c0f1ca0cb0c77f93c4d84f3dc79a76d2e9dc7dab062e20c148e5c939678e391?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9c0f1ca0cb0c77f93c4d84f3dc79a76d2e9dc7dab062e20c148e5c939678e391?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/9c0f1ca0cb0c77f93c4d84f3dc79a76d2e9dc7dab062e20c148e5c939678e391?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/9c0f1ca0cb0c77f93c4d84f3dc79a76d2e9dc7dab062e20c148e5c939678e391?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9c0f1ca0cb0c77f93c4d84f3dc79a76d2e9dc7dab062e20c148e5c939678e391?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/9c0f1ca0cb0c77f93c4d84f3dc79a76d2e9dc7dab062e20c148e5c939678e391?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/9c0f1ca0cb0c77f93c4d84f3dc79a76d2e9dc7dab062e20c148e5c939678e391?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
              className="community-img-4"
            />
          </div>
        </div>
        <div className="community-div-17">
          <div className="community-div-18">
            <div className="column-3">
              <div className="community-div-19">
                <div className="community-div-20" />
                <div className="home">Home</div>
                <div className="shop-2">Shop</div>
                <div className="about">About</div>
                <div className="community-2">Community</div>
              </div>
            </div>
            <div className="column-4">
              <div className="community-div-21">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/2071c76c005be67083c40540847dfd5bb1c6f272b6c11865ba55ecd9d52c385e?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                  className="community-img-5"
                />
                <div className="argentum">ARGENTUM</div>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/e6fdac41435667c1e8acf5467dd79aeedec8b449fb3598f7df6756c0e0736b9a?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                  className="community-img-6"
                />
                <div className="community-div-22" />
                <div className="weekly-newsletter">Weekly Newsletter</div>
                <div className="community-div-23">
                  <div className="email-address">Email Address</div>
                  <div className="subscribe">Subscribe</div>
                </div>
              </div>
            </div>
            <div className="column-5">
              <div className="community-div-24">
                <div className="community-div-25" />
                <div className="info-argentumco-com">info@argentumco.com</div>
                <div className="961-78901035">+961 78901035</div>
                <div className="961-71669247">+961 71669247</div>
                <div className="beirut-lebanon">Beirut - Lebanon </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .community-page {
          background: var(
            --Linear-Background,
            linear-gradient(117deg, #1c1c1c 0%, rgba(192, 192, 192, 0.5) 100%)
          );
          background-color: var(
            --Linear-Background,
            linear-gradient(117deg, #1c1c1c 0%, rgba(192, 192, 192, 0.5) 100%)
          );
          display: flex;
          flex-direction: column;
          overflow: hidden;
        }
        .div {
          background-color: rgba(28, 28, 28, 1);
          box-shadow: 0px 6px 8px rgba(192, 192, 192, 0.5);
          width: 100%;
          overflow: hidden;
          padding: 19px 51px;
        }
        @media (max-width: 991px) {
          .div {
            max-width: 100%;
            padding: 0 20px;
          }
        }
        .div-2 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .div-2 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 83%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column {
            width: 100%;
          }
        }
        .div-3 {
          display: flex;
          width: 100%;
          align-items: center;
          gap: 40px 100px;
          color: rgba(192, 192, 192, 0.6);
          justify-content: space-between;
          flex-wrap: wrap;
          font: 700 20px Raleway, sans-serif;
        }
        @media (max-width: 991px) {
          .div-3 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .ag-logo-2 {
          align-self: stretch;
          display: flex;
          width: 93px;
          height: 63px;
          margin: auto 0;
        }
        .div-4 {
          align-self: stretch;
          display: flex;
          min-width: 240px;
          align-items: center;
          gap: 40px 70px;
          justify-content: start;
          flex-wrap: wrap;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .div-4 {
            max-width: 100%;
          }
        }
        .home-page {
          align-self: stretch;
          margin: auto 0;
        }
        .shop {
          align-self: stretch;
          margin: auto 0;
        }
        .community {
          color: rgba(192, 192, 192, 1);
          align-self: stretch;
          margin: auto 0;
        }
        .about-us {
          align-self: stretch;
          margin: auto 0;
        }
        .column-2 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 17%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-2 {
            width: 100%;
          }
        }
        .div-5 {
          display: flex;
          width: 100%;
          gap: 15px;
          align-self: stretch;
          color: rgba(28, 28, 28, 1);
          margin: auto 0;
          font: 700 15px Poppins, sans-serif;
        }
        @media (max-width: 991px) {
          .div-5 {
            margin-top: 40px;
          }
        }
        .img {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 49px;
          border-radius: 99px;
        }
        .log-in {
          align-self: stretch;
          border-radius: 20px;
          gap: 10px;
          overflow: hidden;
          padding: 13px 40px;
        }
        @media (max-width: 991px) {
          .log-in {
            padding: 0 20px;
          }
        }
        .community-div-6 {
          display: flex;
          flex-direction: column;
          position: relative;
          min-height: 304px;
          width: 100%;
          color: rgba(255, 255, 255, 1);
          font: 700 20px Raleway, sans-serif;
        }
        @media (max-width: 991px) {
          .community-div-6 {
            max-width: 100%;
          }
        }
        .community-img-2 {
          position: absolute;
          inset: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
        .community-div-7 {
          position: relative;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          width: 100%;
          flex-direction: column;
          overflow: hidden;
          align-items: center;
          justify-content: center;
          padding: 57px 80px;
        }
        @media (max-width: 991px) {
          .community-div-7 {
            max-width: 100%;
            padding: 0 20px;
          }
        }
        .community-div-8 {
          display: flex;
          width: 521px;
          max-width: 100%;
          flex-direction: column;
        }
        .join-our-community {
          font-size: 50px;
          letter-spacing: 2px;
          text-align: center;
        }
        @media (max-width: 991px) {
          .join-our-community {
            max-width: 100%;
            font-size: 40px;
          }
        }
        .be-a-part-of-our-mission-to-create-a-sustainable-future-while-enjoying-exclusive-benefits {
          font-weight: 600;
          text-align: center;
          margin-top: 10px;
        }
        @media (max-width: 991px) {
          .be-a-part-of-our-mission-to-create-a-sustainable-future-while-enjoying-exclusive-benefits {
            max-width: 100%;
            margin-right: 8px;
          }
        }
        .join-now {
          align-self: center;
          border-radius: 20px;
          background-color: rgba(192, 192, 192, 1);
          margin-top: 20px;
          gap: 10px;
          overflow: hidden;
          font-family: Poppins, sans-serif;
          color: rgba(28, 28, 28, 1);
          padding: 13px 40px;
        }
        @media (max-width: 991px) {
          .join-now {
            padding: 0 20px;
          }
        }
        .community-div-9 {
          display: flex;
          margin-top: 50px;
          width: 100%;
          flex-direction: column;
          padding: 85px 0 0 50px;
          font: 800 30px Raleway, sans-serif;
        }
        @media (max-width: 991px) {
          .community-div-9 {
            max-width: 100%;
            padding-left: 20px;
            margin-top: 40px;
          }
        }
        .community-img-3 {
          object-fit: contain;
          object-position: center;
          width: 100%;
          border-radius: 15px;
          max-width: 1337px;
        }
        @media (max-width: 991px) {
          .community-img-3 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .community-div-10 {
          z-index: 10;
          display: flex;
          margin-top: -649px;
          flex-direction: column;
          align-items: start;
          justify-content: start;
        }
        @media (max-width: 991px) {
          .community-div-10 {
            max-width: 100%;
            margin-top: -200px;
          }
        }
        .community-div-11 {
          border-radius: 15px;
          display: flex;
          width: 1340px;
          max-width: 100%;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .community-div-11 {
            padding-bottom: 100px;
          }
        }
        .the-environmental-pin {
          align-self: center;
          color: white;
        }
        .community-div-12 {
          box-shadow: 0px 0px 4px rgba(192, 192, 192, 1);
          height: 0px;
          margin: 20px 0 -119px;
        }
        @media (max-width: 991px) {
          .community-div-12 {
            max-width: 100%;
            margin-bottom: 10px;
          }
        }
        .community-div-13 {
          display: flex;
          margin-top: 50px;
          width: 1340px;
          max-width: 100%;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .community-div-13 {
            padding-bottom: 100px;
            margin-top: 40px;
          }
        }
        .upcoming-event {
          align-self: center;
          color: white;
        }
        .community-div-14 {
          box-shadow: 0px 0px 4px rgba(192, 192, 192, 1);
          height: 0px;
          margin: 20px 0 -137px;
        }
        @media (max-width: 991px) {
          .community-div-14 {
            max-width: 100%;
            margin-bottom: 10px;
          }
        }
        .community-div-15 {
          border-radius: 15px;
          display: flex;
          margin-top: 50px;
          width: 1340px;
          max-width: 100%;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .community-div-15 {
            padding-bottom: 100px;
            margin-top: 40px;
          }
        }
        .connect-with-other-members {
          align-self: center;
        }
        @media (max-width: 991px) {
          .connect-with-other-members {
            max-width: 100%;
          }
        }
        .community-div-16 {
          box-shadow: 0px 0px 4px rgba(192, 192, 192, 1);
          height: 0px;
          margin: 20px 0 -83px;
        }
        @media (max-width: 991px) {
          .community-div-16 {
            max-width: 100%;
            margin-bottom: 10px;
          }
        }
        .community-img-4 {
          aspect-ratio: 3.39;
          object-fit: contain;
          object-position: center;
          width: 112%;
          align-self: stretch;
          margin-top: 50px;
        }
        @media (max-width: 991px) {
          .community-img-4 {
            margin-top: 40px;
          }
        }
        .community-div-17 {
          background-color: rgba(192, 192, 192, 0.6);
          margin-top: 50px;
          width: 100%;
          overflow: hidden;
          padding: 20px 49px 31px;
        }
        @media (max-width: 991px) {
          .community-div-17 {
            max-width: 100%;
            margin-top: 40px;
            padding: 0 20px;
          }
        }
        .community-div-18 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .community-div-18 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-3 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 36%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-3 {
            width: 100%;
          }
        }
        .community-div-19 {
          display: flex;
          margin-top: 49px;
          flex-direction: column;
          align-items: center;
          color: rgba(228, 226, 221, 1);
          white-space: nowrap;
          font: 600 15px Raleway, sans-serif;
        }
        @media (max-width: 991px) {
          .community-div-19 {
            margin-top: 40px;
            white-space: initial;
          }
        }
        .community-div-20 {
          align-self: stretch;
          height: 1px;
          border: 1px solid rgba(255, 255, 255, 1);
        }
        .home {
          margin-top: 38px;
        }
        .shop-2 {
          margin-top: 15px;
        }
        .about {
          margin-top: 15px;
        }
        .community-2 {
          margin-top: 15px;
        }
        .column-4 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 27%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-4 {
            width: 100%;
          }
        }
        .community-div-21 {
          display: flex;
          width: 100%;
          flex-direction: column;
          align-items: center;
        }
        @media (max-width: 991px) {
          .community-div-21 {
            margin-top: 40px;
          }
        }
        .community-img-5 {
          aspect-ratio: 0.8;
          object-fit: contain;
          object-position: center;
          width: 47px;
        }
        .argentum {
          color: rgba(228, 226, 221, 1);
          margin-top: 10px;
          font: 400 23px FONTSPRING DEMO - Quincy CF Text, -apple-system, Roboto,
            Helvetica, sans-serif;
        }
        .community-img-6 {
          aspect-ratio: 8.55;
          object-fit: contain;
          object-position: center;
          width: 100%;
          align-self: stretch;
          margin-top: 55px;
        }
        @media (max-width: 991px) {
          .community-img-6 {
            margin-top: 40px;
          }
        }
        .community-div-22 {
          margin-top: 40px;
          width: 1px;
          height: 38px;
          border: 1px solid rgba(255, 255, 255, 1);
        }
        .weekly-newsletter {
          color: rgba(228, 226, 221, 1);
          margin-top: 20px;
          font: 600 15px Raleway, sans-serif;
        }
        .community-div-23 {
          border-radius: 15px;
          align-self: stretch;
          display: flex;
          margin-top: 10px;
          width: 100%;
          gap: 20px;
          overflow: hidden;
          font-size: 12px;
          font-weight: 700;
          justify-content: space-between;
          padding: 0 1px 0 15px;
          border: 1px solid rgba(192, 192, 192, 1);
        }
        @media (max-width: 991px) {
          .community-div-23 {
            margin: 0 6px 0 7px;
          }
        }
        .email-address {
          color: rgba(228, 226, 221, 0.6);
          font-family: Lato, sans-serif;
          margin: auto 0;
        }
        .subscribe {
          align-self: stretch;
          background-color: rgba(192, 192, 192, 1);
          min-height: 33px;
          gap: 10px;
          font-family: Poppins, sans-serif;
          color: rgba(28, 28, 28, 0.7);
          white-space: nowrap;
          padding: 8px 20px;
        }
        @media (max-width: 991px) {
          .subscribe {
            white-space: initial;
          }
        }
        .column-5 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 36%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-5 {
            width: 100%;
          }
        }
        .community-div-24 {
          display: flex;
          margin-top: 49px;
          flex-direction: column;
          align-items: center;
          color: rgba(228, 226, 221, 1);
          font: 600 15px Raleway, sans-serif;
        }
        @media (max-width: 991px) {
          .community-div-24 {
            margin-top: 40px;
          }
        }
        .community-div-25 {
          align-self: stretch;
          height: 1px;
          border: 1px solid rgba(255, 255, 255, 1);
        }
        .info-argentumco-com {
          margin-top: 38px;
        }
        .961-78901035 {
          margin-top: 15px;
        }
        .961-71669247 {
          margin-top: 15px;
        }
        .beirut-lebanon {
          margin-top: 15px;
        }
      `}</style>
    </>
  );
}
export default Community;
