import * as React from "react";

function Connect() {
  return (
    <>
      <div className="connect-div">
        <div className="join-our-community-of-500-eco-conscious-individuals">
          Join our community of{" "}
          <span style={{ color: "rgba(107, 175, 146, 1)" }}>
            500+ eco-conscious individuals!
          </span>
        </div>
        <div className="join-like-minded-groups-and-channels">
          Join Like-Minded Groups and Channels
        </div>
        <div className="members-can-connect-with-others-interested-in-silver-investments-sustainable-living-or-jewelry-design-each-group-or-channel-provides-a-space-to-share-insights-tips-and-stay-updated-on-market-trends">
          Members can connect with others interested in silver investments,
          sustainable living, or jewelry design. Each group or channel
          <br />
          provides a space to share insights, tips, and stay updated on market
          trends.{" "}
        </div>
        <div className="direct-messaging-and-networking-opportunities">
          Direct Messaging and Networking Opportunities
        </div>
        <div className="enable-members-to-message-each-other-directly-fostering-networking-and-knowledge-sharing-opportunities-within-the-silver-investment-and-jewelry-community">
          Enable members to message each other directly, fostering networking
          and knowledge-sharing opportunities within the silver investment and
          jewelry community.
        </div>
        <div className="connect-div-2">
          <div className="join-the-community">Join the Community</div>
        </div>
      </div>
      <style jsx>{`
        .connect-div {
          border-radius: 15px;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          flex-direction: column;
          overflow: hidden;
          align-items: center;
          font-family: Raleway, sans-serif;
          font-weight: 700;
          padding: 32px 80px 14px;
          background-image: url("/image.png"); /* Use the correct path here */
          background-size: cover;
          background-position: center;
        }
        @media (max-width: 991px) {
          .div {
            padding: 0 20px;
          }
        }

        .join-our-community-of-500-eco-conscious-individuals {
          font-size: 39px;
          font-weight: 800;
        }
        @media (max-width: 991px) {
          .join-our-community-of-500-eco-conscious-individuals {
            max-width: 100%;
          }
        }
        .join-like-minded-groups-and-channels {
          color: rgba(107, 175, 146, 1);
          font-size: 25px;
          margin-top: 30px;
        }
        @media (max-width: 991px) {
          .join-like-minded-groups-and-channels {
            max-width: 100%;
          }
        }
        .members-can-connect-with-others-interested-in-silver-investments-sustainable-living-or-jewelry-design-each-group-or-channel-provides-a-space-to-share-insights-tips-and-stay-updated-on-market-trends {
          color: rgba(255, 255, 255, 1);
          font-size: 20px;
          font-weight: 500;
          text-align: center;
          align-self: stretch;
          margin-top: 10px;
        }
        @media (max-width: 991px) {
          .members-can-connect-with-others-interested-in-silver-investments-sustainable-living-or-jewelry-design-each-group-or-channel-provides-a-space-to-share-insights-tips-and-stay-updated-on-market-trends {
            max-width: 100%;
          }
        }
        .direct-messaging-and-networking-opportunities {
          color: rgba(107, 175, 146, 1);
          font-size: 25px;
          margin-top: 15px;
        }
        @media (max-width: 991px) {
          .direct-messaging-and-networking-opportunities {
            max-width: 100%;
          }
        }
        .enable-members-to-message-each-other-directly-fostering-networking-and-knowledge-sharing-opportunities-within-the-silver-investment-and-jewelry-community {
          color: rgba(255, 255, 255, 1);
          font-size: 20px;
          font-weight: 500;
          text-align: center;
          align-self: stretch;
          margin-top: 10px;
        }
        @media (max-width: 991px) {
          .enable-members-to-message-each-other-directly-fostering-networking-and-knowledge-sharing-opportunities-within-the-silver-investment-and-jewelry-community {
            max-width: 100%;
            margin-right: 4px;
          }
        }
        .connect-div-2 {
          display: flex;
          flex-direction: column;
          border-radius: 20px;
          position: relative;
          margin-top: 7px;
          min-height: 55px;
          align-items: center;
          gap: 10px;
          overflow: hidden;
          color: rgba(28, 28, 28, 1);
          justify-content: center;
          padding: 11px 11px 11px 12px;
          font: 15px Poppins, sans-serif;
        }
        .img {
          position: absolute;
          inset: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
        .join-the-community {
          position: relative;
          border-radius: 20px;
          background-color: rgba(192, 192, 192, 1);
          gap: 10px;
          overflow: hidden;
          margin: auto 0;
          padding: 13px 25px;
        }
        @media (max-width: 991px) {
          .join-the-community {
            padding: 0 20px;
          }
        }
      `}</style>
    </>
  );
}

export default Connect;
