import * as React from "react";

function Pin() {
  return (
    <>
      <div className="pin-div">
        <div className="your-silver-pin-our-green-future">
          Your Silver Pin, Our{" "}
          <span style={{ color: "rgba(126, 188, 100, 1)" }}>Green</span> Future
        </div>
        <div className="what-is-the-community-pin">
          What is the Community Pin?
        </div>
        <div className="a-unique-initiative-by-argentum-that-allows-members-to-contribute-to-a-sustainable-future-by-joining-members-earn-a-pin-that-signifies-their-dedication-to-the-environment">
          A unique initiative by Argentum that allows members to contribute to
          <br />a sustainable future. By joining, members earn a &quot;pin&quot;
          that signifies
          <br />
          their dedication to the environment.
        </div>
        <div className="pin-details">Pin Details:</div>
        <div className="pin-div-2">
          <div className="represents-your-commitment-to-environmental-protection-and-community-wear-it-as-a-badge-of-honor-while-contributing-to-sustainability-efforts">
            Represents your commitment to environmental protection
            <br />
            and community. Wear it as a badge of honor while
            <br />
            contributing to sustainability efforts.
          </div>
          <div className="pin-div-3">
            <div className="purity-100-recycled-silver">
              Purity: 100% recycled silver.
            </div>
            <div className="weight-5-grams">Weight: 5 grams</div>
          </div>
        </div>
        <div className="exclusive-benefits-for-pin-holders">
          Exclusive Benefits for Pin Holders
        </div>
        <div className="access-to-eco-conscious-events-special-invitations-to-events-rewards-and-discounts-earn-rewards-such-as-discounts-on-argentum-products-and-environmental-shops">
          <ul>
            <li>
              <span style={{ fontWeight: 600 }}>
                Access to Eco-Conscious Events:
              </span>{" "}
              Special invitations to events
            </li>
            <li>
              <span style={{ fontWeight: 600 }}>Rewards and Discounts:</span>{" "}
              Earn rewards, such as discounts onArgentum products and
              environmental shops 
            </li>
          </ul>
        </div>
        <div className="pin-div-4">
          <div className="buy-for-only-15-99">Buy for only $15.99</div>
        </div>
      </div>
      <style jsx>{`
        .pin-div {
          border-radius: 15px;
          background-color: rgba(0, 0, 0, 0.25);
          box-shadow: 0px 0px 10px rgba(107, 175, 146, 1);
          display: flex;
          flex-direction: column;
          overflow: hidden;
          align-items: start;
          font: 700 25px Raleway, sans-serif;
          margin-top:43px;
        }
        @media (max-width: 991px) {
          .div {
            padding: 0 20px;
          }
        }
        .your-silver-pin-our-green-future {
          font-size: 40px;
          font-weight: 800;
        }
        @media (max-width: 991px) {
          .your-silver-pin-our-green-future {
            max-width: 100%;
          }
        }
        .what-is-the-community-pin {
          color: rgba(255, 255, 255, 1);
          margin-top: 20px;
        }
        .a-unique-initiative-by-argentum-that-allows-members-to-contribute-to-a-sustainable-future-by-joining-members-earn-a-pin-that-signifies-their-dedication-to-the-environment {
          color: rgba(255, 255, 255, 1);
          font-size: 20px;
          font-weight: 500;
          margin-top: 10px;
        }
        @media (max-width: 991px) {
          .a-unique-initiative-by-argentum-that-allows-members-to-contribute-to-a-sustainable-future-by-joining-members-earn-a-pin-that-signifies-their-dedication-to-the-environment {
            max-width: 100%;
          }
        }
        .pin-details {
          color: rgba(255, 255, 255, 1);
          margin-top: 15px;
        }
        .pin-div-2 {
          display: flex;
          margin-top: 10px;
          width: 543px;
          max-width: 100%;
          flex-direction: column;
          font-size: 20px;
          color: rgba(255, 255, 255, 1);
          font-weight: 500;
          justify-content: start;
        }
        .represents-your-commitment-to-environmental-protection-and-community-wear-it-as-a-badge-of-honor-while-contributing-to-sustainability-efforts {
        }
        @media (max-width: 991px) {
          .represents-your-commitment-to-environmental-protection-and-community-wear-it-as-a-badge-of-honor-while-contributing-to-sustainability-efforts {
            max-width: 100%;
          }
        }
        .pin-div-3 {
          border-radius: 0px 0px 0px 0px;
          display: flex;
          margin-top: 10px;
          width: 507px;
          max-width: 100%;
          gap: 40px;
          flex-wrap: wrap;
        }
        .purity-100-recycled-silver {
          flex-grow: 1;
          flex-basis: auto;
        }
        .weight-5-grams {
          flex-grow: 1;
          width: 146px;
        }
        .exclusive-benefits-for-pin-holders {
          color: rgba(255, 255, 255, 1);
          margin-top: 15px;
        }
        .access-to-eco-conscious-events-special-invitations-to-events-rewards-and-discounts-earn-rewards-such-as-discounts-on-argentum-products-and-environmental-shops {
          color: rgba(255, 255, 255, 1);
          font-size: 20px;
          font-weight: 500;
          margin-top: 10px;
        }
        @media (max-width: 991px) {
          .access-to-eco-conscious-events-special-invitations-to-events-rewards-and-discounts-earn-rewards-such-as-discounts-on-argentum-products-and-environmental-shops {
            max-width: 100%;
          }
        }
        .pin-div-4 {
          border-radius: 20px;
          background-color: rgba(107, 175, 146, 1);
          display: flex;
          margin-top: 20px;
          min-height: 55px;
          width: 198px;
          max-width: 100%;
          align-items: center;
          gap: 10px;
          overflow: hidden;
          color: rgba(28, 28, 28, 1);
          justify-content: center;
          padding: 3px;
          font: 15px Poppins, sans-serif;
        }
        .buy-for-only-15-99 {
          align-self: stretch;
          border-radius: 20px;
          background-color: rgba(107, 175, 146, 1);
          gap: 10px;
          overflow: hidden;
          margin: auto 0;
          padding: 13px 25px;
        }
        @media (max-width: 991px) {
          .buy-for-only-15-99 {
            padding: 0 20px;
          }
        }
      `}</style>
    </>
  );
}

export default Pin;
