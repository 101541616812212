import * as React from "react";

function Sellers() {
  return (
    <>
      <div className="sellers-div">
        <div className="sellers-div-2">
          <div className="sellers-div-3">
            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/e70bd2146dd51b20fa27123b05621526b1a9793fdc64597a6b7883f0e2433a9d?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/e70bd2146dd51b20fa27123b05621526b1a9793fdc64597a6b7883f0e2433a9d?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e70bd2146dd51b20fa27123b05621526b1a9793fdc64597a6b7883f0e2433a9d?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/e70bd2146dd51b20fa27123b05621526b1a9793fdc64597a6b7883f0e2433a9d?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/e70bd2146dd51b20fa27123b05621526b1a9793fdc64597a6b7883f0e2433a9d?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e70bd2146dd51b20fa27123b05621526b1a9793fdc64597a6b7883f0e2433a9d?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/e70bd2146dd51b20fa27123b05621526b1a9793fdc64597a6b7883f0e2433a9d?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/e70bd2146dd51b20fa27123b05621526b1a9793fdc64597a6b7883f0e2433a9d?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
              className="sellers-img"
            />
            <div className="silver-necklace-main">Silver Necklace</div>
          </div>
          <div className="sellers-div-4">
            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/d10860aa330b20e357947f0d0d7346162467eb875ac42d276134c52276c636f6?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/d10860aa330b20e357947f0d0d7346162467eb875ac42d276134c52276c636f6?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d10860aa330b20e357947f0d0d7346162467eb875ac42d276134c52276c636f6?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/d10860aa330b20e357947f0d0d7346162467eb875ac42d276134c52276c636f6?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/d10860aa330b20e357947f0d0d7346162467eb875ac42d276134c52276c636f6?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d10860aa330b20e357947f0d0d7346162467eb875ac42d276134c52276c636f6?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/d10860aa330b20e357947f0d0d7346162467eb875ac42d276134c52276c636f6?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/d10860aa330b20e357947f0d0d7346162467eb875ac42d276134c52276c636f6?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
              className="sellers-img"
            />
            <div className="silver-necklace-main">Silver Necklace</div>
          </div>
          <div className="sellers-div-5">
            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/067ca065407de9f291b2f2513ae40e4c94410d8fdaed83809dcf65fc29b3c70c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/067ca065407de9f291b2f2513ae40e4c94410d8fdaed83809dcf65fc29b3c70c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/067ca065407de9f291b2f2513ae40e4c94410d8fdaed83809dcf65fc29b3c70c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/067ca065407de9f291b2f2513ae40e4c94410d8fdaed83809dcf65fc29b3c70c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/067ca065407de9f291b2f2513ae40e4c94410d8fdaed83809dcf65fc29b3c70c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/067ca065407de9f291b2f2513ae40e4c94410d8fdaed83809dcf65fc29b3c70c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/067ca065407de9f291b2f2513ae40e4c94410d8fdaed83809dcf65fc29b3c70c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/067ca065407de9f291b2f2513ae40e4c94410d8fdaed83809dcf65fc29b3c70c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
              className="sellers-img"
            />
            <div className="sterling-silver-ring">Sterling Silver Ring</div>
          </div>
          <div className="sellers-div-6">
            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/213c1dedcd99da94e4321bd6142b56244972e6daa0883077c94462032ba2857b?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/213c1dedcd99da94e4321bd6142b56244972e6daa0883077c94462032ba2857b?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/213c1dedcd99da94e4321bd6142b56244972e6daa0883077c94462032ba2857b?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/213c1dedcd99da94e4321bd6142b56244972e6daa0883077c94462032ba2857b?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/213c1dedcd99da94e4321bd6142b56244972e6daa0883077c94462032ba2857b?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/213c1dedcd99da94e4321bd6142b56244972e6daa0883077c94462032ba2857b?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/213c1dedcd99da94e4321bd6142b56244972e6daa0883077c94462032ba2857b?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/213c1dedcd99da94e4321bd6142b56244972e6daa0883077c94462032ba2857b?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
              className="sellers-img-2"
            />
            <div className="silver-stud-earring">Silver Stud Earring</div>
          </div>
      
        </div>
        <div className="sellers-div-8">
          <div className="sellers-div-9">
            
            <div className="view-all-products">View All Products</div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .sellers-div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: start;
        }
        .sellers-div-2 {
          display: flex;
          width: 100%;
          gap: 40px;
        }
        @media (max-width: 991px) {
          .sellers-div-2 {
            max-width: 100%;
          }
        }
        .sellers-div-3 {
          border-radius: 15px;
          box-shadow: -6px 8px 10px rgba(192, 192, 192, 0.25);
          display: flex;
          padding-bottom: 17px;
          flex-direction: column;
          overflow: hidden;
          color: rgba(28, 28, 28, 1);
          flex: 1;
          font: 700 20px Lato, sans-serif;
        }
        .sellers-img {
          aspect-ratio: 1.19;
          object-fit: contain;
          object-position: center;
          width: 100%;
        }
        .silver-necklace-main {
          align-self: center;
          margin-top: 10px;
        }
        .sellers-div-4 {
          border-radius: 15px;
          box-shadow: -6px 8px 10px rgba(192, 192, 192, 0.25);
          display: flex;
          padding-bottom: 17px;
          flex-direction: column;
          overflow: hidden;
          color: rgba(28, 28, 28, 1);
          flex: 1;
          font: 700 20px Lato, sans-serif;
        }
        .sellers-div-5 {
          border-radius: 15px;
          box-shadow: -6px 8px 10px rgba(192, 192, 192, 0.25);
          display: flex;
          padding-bottom: 17px;
          flex-direction: column;
          overflow: hidden;
          color: rgba(28, 28, 28, 1);
          flex: 1;
          font: 700 20px Lato, sans-serif;
        }
        .sterling-silver-ring {
          align-self: center;
          margin-top: 10px;
        }
        .sellers-div-6 {
          border-radius: 15px;
          box-shadow: -6px 8px 10px rgba(192, 192, 192, 0.25);
          display: flex;
          padding-bottom: 17px;
          flex-direction: column;
          overflow: hidden;
          color: rgba(28, 28, 28, 1);
          flex: 1;
          font: 700 20px Lato, sans-serif;
        }
        .sellers-img-2 {
          aspect-ratio: 1.2;
          object-fit: contain;
          object-position: center;
          width: 100%;
        }
        .silver-stud-earring {
          align-self: center;
          margin-top: 12px;
        }
        .sellers-div-7 {
          border-radius: 15px;
          box-shadow: -6px 8px 10px rgba(192, 192, 192, 0.25);
          z-index: 10;
          display: flex;
          margin-right: -315px;
          padding-bottom: 51px;
          flex-direction: column;
          overflow: hidden;
          align-items: start;
          flex: 1;
        }
        .sellers-img-3 {
          aspect-ratio: 0.13;
          object-fit: contain;
          object-position: center;
          width: 34px;
        }
        .sellers-div-8 {
          border-radius: 20px;
          display: flex;
          margin-top: 30px;
          width: 220px;
          max-width: 100%;
          flex-direction: column;
          align-items: start;
          color: rgba(28, 28, 28, 1);
          font: 700 15px Poppins, sans-serif;
        }
        .sellers-div-9 {
          display: flex;
          flex-direction: column;
          border-radius: 20px;
          position: relative;
          z-index: 10;
          aspect-ratio: 3.324;
          min-height: 55px;
          width: 100%;
          align-items: center;
          gap: 10px;
          overflow: hidden;
          justify-content: center;
          margin: -8px 0;
          padding: 11px;
        }
        @media (max-width: 991px) {
          .sellers-div-9 {
            margin-right: -8px;
          }
        }
        .sellers-img-4 {
          position: absolute;
          inset: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
        .view-all-products {
          position: relative;
          align-self: stretch;
          border-radius: 20px;
          background-color: rgba(192, 192, 192, 1);
          gap: 10px;
          overflow: hidden;
          margin: auto 0;
          padding: 13px 40px;
        }
        @media (max-width: 991px) {
          .view-all-products {
            padding: 0 20px;
          }
        }
      `}</style>
    </>
  );
}

export default Sellers;
