import * as React from "react";

function Collection() {
  return (
    <>
      <div className="collection-div">
        <div className="collection-div-2">
          <div className="collection-div-3">
            <div className="wear-the-change-argentums-environmental-pin">
              Wear the Change: Argentum's Environmental Pin
            </div>
            <div className="our-silver-pins-are-more-than-just-jewelry-theyre-a-symbol-of-your-commitment-to-sustainability-join-our-community-of-environmental-stewards-and-make-a-difference">
              Our silver pins are more than just jewelry-they're a symbol of
              your commitment to sustainability. Join our community of
              environmental stewards and make a difference.
            </div>
            <div className="collection-div-4">
              <div className="join-the-movement">Join the Movement</div>
            </div>
            <div className="collection-div-5">
              <div className="learn-more">Learn more</div>
            </div>
          </div>
          <div className="collection-div-6">
            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/0e145cab103679cf334d70eb4020ddc861d1cb6f10d466705d4fa54f0d7c496e?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/0e145cab103679cf334d70eb4020ddc861d1cb6f10d466705d4fa54f0d7c496e?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0e145cab103679cf334d70eb4020ddc861d1cb6f10d466705d4fa54f0d7c496e?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/0e145cab103679cf334d70eb4020ddc861d1cb6f10d466705d4fa54f0d7c496e?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/0e145cab103679cf334d70eb4020ddc861d1cb6f10d466705d4fa54f0d7c496e?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0e145cab103679cf334d70eb4020ddc861d1cb6f10d466705d4fa54f0d7c496e?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/0e145cab103679cf334d70eb4020ddc861d1cb6f10d466705d4fa54f0d7c496e?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/0e145cab103679cf334d70eb4020ddc861d1cb6f10d466705d4fa54f0d7c496e?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
              className="collection-img-2"
            />
          </div>
        </div>
      </div>
      <style jsx>{`
        .collection-div {
          display: flex;
          flex-direction: column;
        }
        .collection-div-2 {
          display: flex;
          align-items: start;
          gap: 37px;
          justify-content: space-between;
          flex-wrap: wrap;
        }
        .collection-div-3 {
          border-radius: 20px;
          display: flex;
          min-width: 240px;
          flex-direction: column;
          color: rgba(192, 192, 192, 1);
          width: 629px;
          font: 700 15px Poppins, sans-serif;
        }
        @media (max-width: 991px) {
          .collection-div-3 {
            max-width: 100%;
          }
        }
        .wear-the-change-argentums-environmental-pin {
          font: 700 40px Raleway, sans-serif;
        }
        @media (max-width: 991px) {
          .wear-the-change-argentums-environmental-pin {
            max-width: 100%;
          }
        }
        .our-silver-pins-are-more-than-just-jewelry-theyre-a-symbol-of-your-commitment-to-sustainability-join-our-community-of-environmental-stewards-and-make-a-difference {
          margin-top: 12px;
          font: 500 25px Lato, sans-serif;
        }
        @media (max-width: 991px) {
          .our-silver-pins-are-more-than-just-jewelry-theyre-a-symbol-of-your-commitment-to-sustainability-join-our-community-of-environmental-stewards-and-make-a-difference {
            max-width: 100%;
          }
        }
        .collection-div-4 {
          display: flex;
          flex-direction: column;
          border-radius: 20px;
          position: relative;
          z-index: 10;
          aspect-ratio: 3.394;
          margin-top: 17px;
          width: 241px;
          max-width: 100%;
          overflow: hidden;
          color: rgba(28, 28, 28, 1);
          justify-content: center;
          padding: 11px 2px;
        }
        .img {
          position: absolute;
          inset: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
        .join-the-movement {
          position: relative;
          align-self: stretch;
          border-radius: 20px;
          background-color: rgba(192, 192, 192, 1);
          gap: 10px;
          overflow: hidden;
          padding: 13px 40px;
        }
        @media (max-width: 991px) {
          .join-the-movement {
            padding: 0 20px;
          }
        }
        .collection-div-5 {
          display: flex;
          flex-direction: column;
          border-radius: 20px;
          position: relative;
          z-index: 10;
          min-height: 55px;
          width: 100%;
          align-items: left;
          gap: 10px;
          overflow: hidden;
          color: rgba(28, 28, 28, 1);
          justify-content: center;
          padding: 10px 11px 10px 2px;
        }
        .learn-more {
          position: relative;
          border-radius: 20px;
          background-color: rgba(192, 192, 192, 1);
          gap: 10px;
          margin: auto 0;
          width: fit-content;
          padding: 13px 40px;
        }
        @media (max-width: 991px) {
          .learn-more {
            padding: 0 20px;
          }
        }
        .collection-div-6 {
          border-radius: 10px;
          display: flex;
          min-width: 240px;
          flex-direction: column;
          overflow: hidden;
          width: 676px;
        }
        @media (max-width: 991px) {
          .collection-div-6 {
            max-width: 100%;
          }
        }
        .collection-img-2 {
          aspect-ratio: 1.77;
          object-fit: contain;
          object-position: center;
          width: 100%;
        }
        @media (max-width: 991px) {
          .collection-img-2 {
            max-width: 100%;
          }
        }
      `}</style>
    </>
  );
}

export default Collection;
