import * as React from "react";
import Navbar from "./nav";

function About() {
  return (
    <>
      <Navbar />
      <div className="about-us">
        <div className="about-div-6">
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/e84bf4e545704d2faffa41d341be0015ba987141ded2a4fae6ab3df56e3dab06?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/e84bf4e545704d2faffa41d341be0015ba987141ded2a4fae6ab3df56e3dab06?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e84bf4e545704d2faffa41d341be0015ba987141ded2a4fae6ab3df56e3dab06?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/e84bf4e545704d2faffa41d341be0015ba987141ded2a4fae6ab3df56e3dab06?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/e84bf4e545704d2faffa41d341be0015ba987141ded2a4fae6ab3df56e3dab06?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e84bf4e545704d2faffa41d341be0015ba987141ded2a4fae6ab3df56e3dab06?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/e84bf4e545704d2faffa41d341be0015ba987141ded2a4fae6ab3df56e3dab06?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/e84bf4e545704d2faffa41d341be0015ba987141ded2a4fae6ab3df56e3dab06?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
            className="about-img-2"
          />
          <div className="about-div-7">
            <div className="about-div-8">
              <div className="crafting-value-with-purpose">
                Crafting Value with Purpose
              </div>
              <div className="at-argentum-were-committed-to-creating-more-than-just-jewelry-our-pieces-are-crafted-with-care-using-sustainably-sourced-and-recycled-silver-honoring-both-tradition-and-environmental-responsibility-each-design-embodies-our-belief-in-quality-elegance-and-a-brighter-future-for-our-planet">
                At Argentum, we’re committed to creating more than just jewelry.
                Our pieces are crafted with care, using sustainably sourced and
                recycled silver, honoring both tradition and environmental
                responsibility. Each design embodies our belief in quality,
                elegance, and a brighter future for our planet.
              </div>
            </div>
          </div>
        </div>
        <div className="about-div-9">
          <div className="about-div-10">
            <div className="about-div-11">
              <div className="column-3">
                <div className="about-div-12">
                  <div className="our-mission">Our Mission</div>
                  <div className="crafting-elegance-protecting-the-planet">
                    Crafting Elegance, Protecting the Planet
                  </div>
                  <div className="at-argentum-our-mission-is-to-lead-the-transformation-of-waste-into-high-quality-sustainable-jewelry-by-extracting-and-repurposing-silver-from-industrial-waste-we-create-luxurious-eco-conscious-pieces-that-reflect-our-commitment-to-environmental-stewardship-and-responsibility-we-aim-to-offer-our-customers-not-only-elegant-jewelry-but-also-an-opportunity-to-contribute-positively-to-the-planet">
                    At Argentum, our mission is to lead the transformation of
                    waste into high-quality, sustainable jewelry. <br />
                    <br />
                    By extracting and repurposing silver from industrial waste,
                    we create luxurious, eco-conscious pieces that reflect our
                    commitment to environmental stewardship and responsibility.{" "}
                    <br />
                    <br />
                    We aim to offer our customers not only elegant jewelry but
                    also an opportunity to contribute positively to the planet.
                  </div>
                </div>
              </div>
              <div className="column-4">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/96d545d7807c16f97c365e1476fcd6d72ebad5ca7af85aee40879a5a5091517c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/96d545d7807c16f97c365e1476fcd6d72ebad5ca7af85aee40879a5a5091517c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/96d545d7807c16f97c365e1476fcd6d72ebad5ca7af85aee40879a5a5091517c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/96d545d7807c16f97c365e1476fcd6d72ebad5ca7af85aee40879a5a5091517c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/96d545d7807c16f97c365e1476fcd6d72ebad5ca7af85aee40879a5a5091517c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/96d545d7807c16f97c365e1476fcd6d72ebad5ca7af85aee40879a5a5091517c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/96d545d7807c16f97c365e1476fcd6d72ebad5ca7af85aee40879a5a5091517c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/96d545d7807c16f97c365e1476fcd6d72ebad5ca7af85aee40879a5a5091517c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                  className="about-img-3"
                />
              </div>
            </div>
          </div>
          <div className="our-vision">Our Vision</div>
        </div>
        <div className="about-div-13" />
        <div className="about-div-14">
          <div className="about-div-15">
            <div className="column-5">
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/3d3a81d501943cf49e7eb57529d91b5ab1d4096ef889351f6f26e10dd27eee05?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d3a81d501943cf49e7eb57529d91b5ab1d4096ef889351f6f26e10dd27eee05?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d3a81d501943cf49e7eb57529d91b5ab1d4096ef889351f6f26e10dd27eee05?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d3a81d501943cf49e7eb57529d91b5ab1d4096ef889351f6f26e10dd27eee05?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d3a81d501943cf49e7eb57529d91b5ab1d4096ef889351f6f26e10dd27eee05?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d3a81d501943cf49e7eb57529d91b5ab1d4096ef889351f6f26e10dd27eee05?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d3a81d501943cf49e7eb57529d91b5ab1d4096ef889351f6f26e10dd27eee05?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d3a81d501943cf49e7eb57529d91b5ab1d4096ef889351f6f26e10dd27eee05?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                className="about-img-4"
              />
            </div>
            <div className="column-6">
              <div className="about-div-16">
                <div className="leading-the-green-revolution-in-jewelry">
                  Leading the Green Revolution in Jewelry
                </div>
                <div className="argentum-envisions-a-future-where-sustainability-and-luxury-coalesce-seamlessly-we-aspire-to-be-a-global-leader-in-eco-friendly-jewelry-inspiring-change-across-the-industry-by-setting-new-standards-in-sustainable-sourcing-ethical-craftsmanship-and-environmental-impact-our-vision-is-to-elevate-recycled-silver-into-a-symbol-of-responsible-elegance-influencing-the-future-of-jewelry-and-protecting-our-planet">
                  Argentum envisions a future where sustainability and luxury
                  coalesce seamlessly. <br />
                  <br />
                  We aspire to be a global leader in eco-friendly jewelry,
                  inspiring change across the industry by setting new standards
                  in sustainable sourcing, ethical craftsmanship, and
                  environmental impact. <br />
                  <br />
                  Our vision is to elevate recycled silver into a symbol of
                  responsible elegance, influencing the future of jewelry and
                  protecting our planet.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="our-story">Our Story</div>
        <div className="about-div-17" />
        <div className="in-a-world-where-waste-often-goes-unnoticed-two-passionate-young-women-embarked-on-a-journey-fueled-by-curiosity-and-a-shared-commitment-to-sustainability-what-began-as-an-environmental-project-sparked-an-exploration-into-the-potential-of-discarded-materials-leading-us-to-the-hidden-treasure-within-used-x-ray-films-their-precious-silver-content-through-countless-experiments-and-innovative-techniques-we-discovered-the-best-eco-friendly-methods-to-extract-silver-from-various-discarded-wastes-with-remarkable-purity-this-scientific-breakthrough-ignited-a-dream-to-transform-this-high-quality-recycled-silver-into-exquisite-jewelry-that-embodies-both-luxury-and-environmental-consciousness">
          In a world where waste often goes unnoticed, two passionate young
          women embarked on a journey fueled by curiosity and a shared
          commitment to sustainability. What began as an environmental project
          sparked an exploration into the potential of discarded materials,
          leading us to the hidden treasure within used X-ray films-their
          precious silver content.
          <br />
          <br />
          Through countless experiments and innovative techniques, we discovered
          the best eco-friendly methods to extract silver from various discarded
          wastes with remarkable purity. This scientific breakthrough ignited a
          dream: to transform this high-quality, recycled silver into exquisite
          jewelry that embodies both luxury and environmental consciousness.
        </div>
        <div className="about-div-18">
          <div className="about-div-19">
            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/a916d33a8d791077933b7fb9adba2ab0bb2287b770d52a9da97e6765aef2c336?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/a916d33a8d791077933b7fb9adba2ab0bb2287b770d52a9da97e6765aef2c336?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a916d33a8d791077933b7fb9adba2ab0bb2287b770d52a9da97e6765aef2c336?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/a916d33a8d791077933b7fb9adba2ab0bb2287b770d52a9da97e6765aef2c336?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/a916d33a8d791077933b7fb9adba2ab0bb2287b770d52a9da97e6765aef2c336?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a916d33a8d791077933b7fb9adba2ab0bb2287b770d52a9da97e6765aef2c336?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/a916d33a8d791077933b7fb9adba2ab0bb2287b770d52a9da97e6765aef2c336?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/a916d33a8d791077933b7fb9adba2ab0bb2287b770d52a9da97e6765aef2c336?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
              className="about-img-5"
            />
            <div className="donia-al-bitar">DONIA AL BITAR</div>
            <div className="co-founder">Co-Founder</div>
          </div>
          <div className="about-about-div-20">
            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/53fbb7b704d533b2b09b4c6d66eb7a3cf19bb0c165e3bdd515d4de2373ee1c29?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/53fbb7b704d533b2b09b4c6d66eb7a3cf19bb0c165e3bdd515d4de2373ee1c29?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/53fbb7b704d533b2b09b4c6d66eb7a3cf19bb0c165e3bdd515d4de2373ee1c29?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/53fbb7b704d533b2b09b4c6d66eb7a3cf19bb0c165e3bdd515d4de2373ee1c29?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/53fbb7b704d533b2b09b4c6d66eb7a3cf19bb0c165e3bdd515d4de2373ee1c29?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/53fbb7b704d533b2b09b4c6d66eb7a3cf19bb0c165e3bdd515d4de2373ee1c29?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/53fbb7b704d533b2b09b4c6d66eb7a3cf19bb0c165e3bdd515d4de2373ee1c29?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/53fbb7b704d533b2b09b4c6d66eb7a3cf19bb0c165e3bdd515d4de2373ee1c29?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
              className="about-img-6"
            />
            <div className="abir-al-bitar">ABIR AL BITAR</div>
            <div className="co-founder-2">Co-Founder</div>
          </div>
        </div>
        <div className="our-project-caught-the-attention-of-various-competitions-where-our-vision-of-marrying-elegance-with-sustainability-resonated-with-many-encouraged-by-the-accolades-and-support-we-took-a-bold-step-forward-turning-our-passion-into-a-thriving-business-today-argentum-stands-as-a-testament-to-our-journey-a-brand-that-weaves-together-the-beauty-of-craftsmanship-with-a-deep-respect-for-the-earth-each-piece-we-create-tells-a-story-of-transformation-innovation-and-a-commitment-to-a-brighter-greener-future">
          Our project caught the attention of various competitions, where our
          vision of marrying elegance with sustainability resonated with many.
          Encouraged by the accolades and support, we took a bold step forward,
          turning our passion into a thriving business.
          <br />
          <br />
          Today, Argentum stands as a testament to our journey-a brand that
          weaves together the beauty of craftsmanship with a deep respect for
          the Earth. Each piece we create tells a story of transformation,
          innovation, and a commitment to a brighter, greener future.
        </div>
        <div className="our-goals-sd-gs">Our Goals (SDGs)</div>
        <div className="about-div-21" />
        <div className="about-div-22">
          <div className="about-div-23">
            <div className="column-7">
              <div className="about-div-24">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/5abf85239c782e36f38b9debd16d4aac7cb4ed1b193799180e60d9297e28bfbc?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/5abf85239c782e36f38b9debd16d4aac7cb4ed1b193799180e60d9297e28bfbc?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5abf85239c782e36f38b9debd16d4aac7cb4ed1b193799180e60d9297e28bfbc?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/5abf85239c782e36f38b9debd16d4aac7cb4ed1b193799180e60d9297e28bfbc?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/5abf85239c782e36f38b9debd16d4aac7cb4ed1b193799180e60d9297e28bfbc?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5abf85239c782e36f38b9debd16d4aac7cb4ed1b193799180e60d9297e28bfbc?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/5abf85239c782e36f38b9debd16d4aac7cb4ed1b193799180e60d9297e28bfbc?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/5abf85239c782e36f38b9debd16d4aac7cb4ed1b193799180e60d9297e28bfbc?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                  className="about-img-7"
                />
                <div className="we-ensure-that-every-piece-of-jewelry-is-crafted-from-recycled-materials-reducing-waste-and-promoting-circular-economy-practices">
                  We ensure that every piece of jewelry is crafted from recycled
                  materials, reducing waste and promoting circular economy
                  practices.
                </div>
              </div>
            </div>
            <div className="column-8">
              <div className="about-div-25">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/344d2b10fa3de24d7f1d1ff59472e50ee2619069c0e58cb188e6ed068fb94105?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/344d2b10fa3de24d7f1d1ff59472e50ee2619069c0e58cb188e6ed068fb94105?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/344d2b10fa3de24d7f1d1ff59472e50ee2619069c0e58cb188e6ed068fb94105?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/344d2b10fa3de24d7f1d1ff59472e50ee2619069c0e58cb188e6ed068fb94105?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/344d2b10fa3de24d7f1d1ff59472e50ee2619069c0e58cb188e6ed068fb94105?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/344d2b10fa3de24d7f1d1ff59472e50ee2619069c0e58cb188e6ed068fb94105?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/344d2b10fa3de24d7f1d1ff59472e50ee2619069c0e58cb188e6ed068fb94105?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/344d2b10fa3de24d7f1d1ff59472e50ee2619069c0e58cb188e6ed068fb94105?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                  className="about-img-8"
                />
                <div className="through-reduced-industrial-waste-and-eco-friendly-production-we-contribute-to-mitigating-environmental-impact">
                  Through reduced industrial waste and eco-friendly production,
                  we contribute to mitigating environmental impact.
                </div>
              </div>
            </div>
            <div className="column-9">
              <div className="about-div-26">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/216409ade648a4339c5517471ea1f5fa5b322a0b532696e4ad208e79b16b4576?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/216409ade648a4339c5517471ea1f5fa5b322a0b532696e4ad208e79b16b4576?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/216409ade648a4339c5517471ea1f5fa5b322a0b532696e4ad208e79b16b4576?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/216409ade648a4339c5517471ea1f5fa5b322a0b532696e4ad208e79b16b4576?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/216409ade648a4339c5517471ea1f5fa5b322a0b532696e4ad208e79b16b4576?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/216409ade648a4339c5517471ea1f5fa5b322a0b532696e4ad208e79b16b4576?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/216409ade648a4339c5517471ea1f5fa5b322a0b532696e4ad208e79b16b4576?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/216409ade648a4339c5517471ea1f5fa5b322a0b532696e4ad208e79b16b4576?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                  className="about-img-9"
                />
                <div className="by-minimizing-harmful-waste-that-can-pollute-oceans-we-help-protect-marine-ecosystems-from-industrial-contamination">
                  By minimizing harmful waste that can pollute oceans, we help
                  protect marine ecosystems from industrial contamination.
                </div>
              </div>
            </div>
            <div className="column-10">
              <div className="about-div-27">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/9c080b060d24a4575ca2ab4b0185689f0ec80634e49f2c2f424b3fb7c38ce495?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/9c080b060d24a4575ca2ab4b0185689f0ec80634e49f2c2f424b3fb7c38ce495?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9c080b060d24a4575ca2ab4b0185689f0ec80634e49f2c2f424b3fb7c38ce495?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/9c080b060d24a4575ca2ab4b0185689f0ec80634e49f2c2f424b3fb7c38ce495?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/9c080b060d24a4575ca2ab4b0185689f0ec80634e49f2c2f424b3fb7c38ce495?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9c080b060d24a4575ca2ab4b0185689f0ec80634e49f2c2f424b3fb7c38ce495?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/9c080b060d24a4575ca2ab4b0185689f0ec80634e49f2c2f424b3fb7c38ce495?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/9c080b060d24a4575ca2ab4b0185689f0ec80634e49f2c2f424b3fb7c38ce495?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                  className="about-img-10"
                />
                <div className="our-sustainable-practices-protect-terrestrial-ecosystems-and-promote-responsible-use-of-natural-resources">
                  Our sustainable practices protect terrestrial ecosystems and
                  promote responsible use of natural resources.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="our-contribution-to-the-environment-so-far">
          Our Contribution to the Environment So Far
        </div>
        <div className="about-div-28" />
        <div className="about-div-29">
          <div className="about-about-div-30">
            <div className="column-11">
              <div className="about-div-31">
                <div className="our-environmental-impact">
                  Our Environmental Impact
                </div>
                <div className="since-our-inception-argentum-has-recycled-over-500-kilograms-of-silver-from-x-ray-films-and-industrial-waste-saving-significant-amounts-of-harmful-material-from-landfills-by-upcycling-this-waste-into-beautiful-jewelry-we-have-reduced-the-environmental-footprint-of-silver-extraction-while-promoting-a-culture-of-sustainability-370-kilograms-of-silver-recycled-250-x-ray-films-saved-from-landfills-150-pieces-of-sustainable-jewelry-sold">
                  Since our inception, Argentum has recycled over 500 kilograms
                  of silver from X-ray films and industrial waste, saving
                  significant amounts of harmful material from landfills.
                  <br />
                  <br />
                  By upcycling this waste into beautiful jewelry, we have
                  reduced the environmental footprint of silver extraction while
                  promoting a culture of sustainability.
                  <br />
                  <br />
                  370 kilograms of silver recycled
                  <br />
                  250 X-ray films saved from landfills
                  <br />
                  150 pieces of sustainable jewelry sold
                </div>
              </div>
            </div>
            <div className="column-12">
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/e817cd2614854955c152bbc4e4a9212d9ab7c182bc15998d25a3140a088ee668?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/e817cd2614854955c152bbc4e4a9212d9ab7c182bc15998d25a3140a088ee668?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e817cd2614854955c152bbc4e4a9212d9ab7c182bc15998d25a3140a088ee668?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/e817cd2614854955c152bbc4e4a9212d9ab7c182bc15998d25a3140a088ee668?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/e817cd2614854955c152bbc4e4a9212d9ab7c182bc15998d25a3140a088ee668?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e817cd2614854955c152bbc4e4a9212d9ab7c182bc15998d25a3140a088ee668?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/e817cd2614854955c152bbc4e4a9212d9ab7c182bc15998d25a3140a088ee668?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/e817cd2614854955c152bbc4e4a9212d9ab7c182bc15998d25a3140a088ee668?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                className="about-img-11"
              />
            </div>
          </div>
        </div>
        <div className="our-certifications">Our Certifications</div>
        <div className="about-div-32" />
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/ad39d471e58c2825392ca2178819fdb7ced0c8cf9bb2607e337172d4ded46549?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
          className="about-img-12"
        />
        <div className="feedback">Feedback</div>
        <div className="about-div-33" />
        <div className="about-div-34">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/7b93910d5741bd39ff13a1d8b92232544a38e5361dfaa12b8f63cf5c5e062191?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
            className="about-img-13"
          />
          <div className="about-div-35">
            <div className="about-div-36">
              <div className="about-div-37">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/c43cd95ea299a28d82f99064c48e81814630735329a0784e115b661ca3fdbe77?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                  className="about-img-14"
                />
                <div className="about-div-38">
                  <div className="liam-carter">Liam Carter</div>
                  <div className="exceptional-quality-and-service">
                    Exceptional Quality and Service
                  </div>
                </div>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/4552b8e5d3c2e3813018dfc4827be9dedb6c45b0cd8502c29bbb1764ed871fb8?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                  className="about-img-15"
                />
              </div>
              <div className="argentums-silver-products-are-truly-top-notch-the-craftsmanship-is-impeccable-and-the-customer-service-team-was-so-helpful-in-guiding-my-investment-highly-recommend-for-anyone-looking-to-invest-in-quality">
                Argentum's silver products are truly top-notch. The
                craftsmanship is impeccable, and the customer service team was
                so helpful in guiding my investment. Highly recommend for anyone
                looking to invest in quality.
              </div>
            </div>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/84b7f8a05b198abc56d7d00af4dee3f306d68026be055ed6e5d026dad81e096d?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
              className="about-img-16"
            />
            <div className="about-div-39">
              <div className="add-feedback">Add Feedback</div>
            </div>
          </div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/071c8efc2bd9359e7da1fc905394eb202b146223ec5ca057c3081e14c48a233c?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
            className="about-img-18"
          />
        </div>
        <div className="about-about-div-40">
          <div className="about-div-41">
            <div className="column-13">
              <div className="about-div-42">
                <div className="about-div-43" />
                <div className="home">Home</div>
                <div className="shop-2">Shop</div>
                <div className="about">About</div>
                <div className="community-2">Community</div>
              </div>
            </div>
            <div className="column-14">
              <div className="about-div-44">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/cc2a2a02fc90b4d39f4d0ed208eb191dd28d18c20187c61ead98404d56396d67?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                  className="about-about-img-19"
                />
                <div className="argentum">ARGENTUM</div>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/58acb1b3946e498161b059bef1e9608f13b8e8eccd24e8ef43f36938bf343187?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                  className="about-about-img-20"
                />
                <div className="about-div-45" />
                <div className="weekly-newsletter">Weekly Newsletter</div>
                <div className="about-div-46">
                  <div className="email-address">Email Address</div>
                  <div className="subscribe">Subscribe</div>
                </div>
              </div>
            </div>
            <div className="column-15">
              <div className="about-div-47">
                <div className="about-div-48" />
                <div className="info-argentumco-com">info@argentumco.com</div>
                <div className="961-78901035">+961 78901035</div>
                <div className="961-71669247">+961 71669247</div>
                <div className="beirut-lebanon">Beirut - Lebanon </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      <style jsx>{`
        .about-us {
          background: var(
            --Linear-Background,
            linear-gradient(117deg, #1c1c1c 0%, rgba(192, 192, 192, 0.5) 100%)
          );
          background-color: var(
            --Linear-Background,
            linear-gradient(117deg, #1c1c1c 0%, rgba(192, 192, 192, 0.5) 100%)
          );
          display: flex;
          flex-direction: column;
          overflow: hidden;
          align-items: center;
        }
        .div {
          background-color: rgba(28, 28, 28, 1);
          box-shadow: 0px 6px 8px rgba(192, 192, 192, 0.5);
          align-self: stretch;
          width: 100%;
          overflow: hidden;
          padding: 19px 51px;
        }
        @media (max-width: 991px) {
          .div {
            max-width: 100%;
            padding: 0 20px;
          }
        }
        .about-div-2 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .about-div-2 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 83%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column {
            width: 100%;
          }
        }
        .about-div-3 {
          display: flex;
          width: 100%;
          align-items: center;
          gap: 40px 100px;
          color: rgba(192, 192, 192, 0.6);
          justify-content: space-between;
          flex-wrap: wrap;
          font: 700 20px Raleway, sans-serif;
        }
        @media (max-width: 991px) {
          .about-div-3 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .ag-logo-2 {
          align-self: stretch;
          display: flex;
          width: 93px;
          height: 63px;
          margin: auto 0;
        }
        .about-div-4 {
          align-self: stretch;
          display: flex;
          min-width: 240px;
          align-items: center;
          gap: 40px 70px;
          justify-content: start;
          flex-wrap: wrap;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .about-div-4 {
            max-width: 100%;
          }
        }
        .home-page {
          align-self: stretch;
          margin: auto 0;
        }
        .shop {
          align-self: stretch;
          margin: auto 0;
        }
        .community {
          align-self: stretch;
          margin: auto 0;
        }
        .about-us-2 {
          color: rgba(192, 192, 192, 1);
          align-self: stretch;
          margin: auto 0;
        }
        .column-2 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 17%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-2 {
            width: 100%;
          }
        }
        .about-div-5 {
          display: flex;
          width: 100%;
          gap: 15px;
          align-self: stretch;
          color: rgba(28, 28, 28, 1);
          margin: auto 0;
          font: 700 15px Poppins, sans-serif;
        }
        @media (max-width: 991px) {
          .about-div-5 {
            margin-top: 40px;
          }
        }
        .img {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 49px;
          border-radius: 99px;
        }
        .log-in {
          align-self: stretch;
          border-radius: 20px;
          gap: 10px;
          overflow: hidden;
          padding: 13px 40px;
        }
        @media (max-width: 991px) {
          .log-in {
            padding: 0 20px;
          }
        }
        .about-div-6 {
          display: flex;
          flex-direction: column;
          align-self: stretch;
          position: relative;
          min-height: 304px;
          width: 100%;
          font-family: Raleway, sans-serif;
          color: rgba(255, 255, 255, 1);
        }
        @media (max-width: 991px) {
          .about-div-6 {
            max-width: 100%;
          }
        }
        .about-img-2 {
          position: absolute;
          inset: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
        .about-div-7 {
          position: relative;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          width: 100%;
          flex-direction: column;
          overflow: hidden;
          align-items: center;
          justify-content: center;
          padding: 69px 80px;
        }
        @media (max-width: 991px) {
          .about-div-7 {
            max-width: 100%;
            padding: 0 20px;
          }
        }
        .about-div-8 {
          display: flex;
          width: 718px;
          max-width: 100%;
          flex-direction: column;
        }
        .crafting-value-with-purpose {
          font-size: 50px;
          font-weight: 700;
          letter-spacing: 2px;
        }
        @media (max-width: 991px) {
          .crafting-value-with-purpose {
            max-width: 100%;
            font-size: 40px;
          }
        }
        .at-argentum-were-committed-to-creating-more-than-just-jewelry-our-pieces-are-crafted-with-care-using-sustainably-sourced-and-recycled-silver-honoring-both-tradition-and-environmental-responsibility-each-design-embodies-our-belief-in-quality-elegance-and-a-brighter-future-for-our-planet {
          font-size: 20px;
          font-weight: 600;
          text-align: center;
          margin-top: 15px;
        }
        @media (max-width: 991px) {
          .at-argentum-were-committed-to-creating-more-than-just-jewelry-our-pieces-are-crafted-with-care-using-sustainably-sourced-and-recycled-silver-honoring-both-tradition-and-environmental-responsibility-each-design-embodies-our-belief-in-quality-elegance-and-a-brighter-future-for-our-planet {
            max-width: 100%;
          }
        }
        .about-div-9 {
          align-self: stretch;
          display: flex;
          margin-top: 50px;
          width: 100%;
          padding-left: 51px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .about-div-9 {
            max-width: 100%;
            padding-left: 20px;
            margin-top: 40px;
          }
        }
        .about-div-10 {
        }
        @media (max-width: 991px) {
          .about-div-10 {
            max-width: 100%;
          }
        }
        .about-div-11 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .about-div-11 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-3 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-3 {
            width: 100%;
          }
        }
        .about-div-12 {
          z-index: 10;
          display: flex;
          margin-right: -73px;
          flex-direction: column;
          font-family: Raleway, sans-serif;
          font-weight: 800;
        }
        @media (max-width: 991px) {
          .about-div-12 {
            max-width: 100%;
          }
        }
        .our-mission {
          font-size: 30px;
          align-self: end;
          margin-right: 30px;
          color: silver;
        }
        @media (max-width: 991px) {
          .our-mission {
            margin-right: 10px;
          }
        }
        .crafting-elegance-protecting-the-planet {
          font-size: 40px;
          margin-top: 50px;
        }
        @media (max-width: 991px) {
          .crafting-elegance-protecting-the-planet {
            max-width: 100%;
            margin-top: 40px;
            color: silver;
          }
        }
        .at-argentum-our-mission-is-to-lead-the-transformation-of-waste-into-high-quality-sustainable-jewelry-by-extracting-and-repurposing-silver-from-industrial-waste-we-create-luxurious-eco-conscious-pieces-that-reflect-our-commitment-to-environmental-stewardship-and-responsibility-we-aim-to-offer-our-customers-not-only-elegant-jewelry-but-also-an-opportunity-to-contribute-positively-to-the-planet {
          color: rgba(255, 255, 255, 1);
          margin-top: 20px;
          font: 400 25px Lato, sans-serif;
        }
        @media (max-width: 991px) {
          .at-argentum-our-mission-is-to-lead-the-transformation-of-waste-into-high-quality-sustainable-jewelry-by-extracting-and-repurposing-silver-from-industrial-waste-we-create-luxurious-eco-conscious-pieces-that-reflect-our-commitment-to-environmental-stewardship-and-responsibility-we-aim-to-offer-our-customers-not-only-elegant-jewelry-but-also-an-opportunity-to-contribute-positively-to-the-planet {
            max-width: 100%;
            margin-right: 8px;
          }
        }
        .column-4 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-4 {
            width: 100%;
          }
        }
        .about-img-3 {
          aspect-ratio: 1.23;
          object-fit: contain;
          object-position: center;
          width: 100%;
          margin-top: 18px;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .about-img-3 {
            max-width: 100%;
          }
        }
        .our-vision {
          align-self: center;
          margin-top: 5px;
          font: 800 30px Raleway, sans-serif;
          color: silver;
        }
        .about-div-13 {
          box-shadow: 0px 0px 4px rgba(192, 192, 192, 1);
          margin-top: 20px;
          width: 1340px;
          max-width: 100%;
          height: 0px;
        }
        .about-div-14 {
          margin-top: 30px;
          width: 100%;
          max-width: 1339px;
        }
        @media (max-width: 991px) {
          .about-div-14 {
            max-width: 100%;
          }
        }
        .about-div-15 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .about-div-15 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-5 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 39%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-5 {
            width: 100%;
          }
        }
        .about-img-4 {
          aspect-ratio: 1.1;
          object-fit: contain;
          object-position: center;
          width: 100%;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .about-img-4 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .column-6 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 61%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-6 {
            width: 100%;
          }
        }
        .about-div-16 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .about-div-16 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .leading-the-green-revolution-in-jewelry {
          font: 800 40px Raleway, sans-serif;
          color: silver;
        }
        @media (max-width: 991px) {
          .leading-the-green-revolution-in-jewelry {
            max-width: 100%;
          }
        }
        .argentum-envisions-a-future-where-sustainability-and-luxury-coalesce-seamlessly-we-aspire-to-be-a-global-leader-in-eco-friendly-jewelry-inspiring-change-across-the-industry-by-setting-new-standards-in-sustainable-sourcing-ethical-craftsmanship-and-environmental-impact-our-vision-is-to-elevate-recycled-silver-into-a-symbol-of-responsible-elegance-influencing-the-future-of-jewelry-and-protecting-our-planet {
          color: rgba(255, 255, 255, 1);
          margin-top: 20px;
          font: 400 25px Lato, sans-serif;
        }
        @media (max-width: 991px) {
          .argentum-envisions-a-future-where-sustainability-and-luxury-coalesce-seamlessly-we-aspire-to-be-a-global-leader-in-eco-friendly-jewelry-inspiring-change-across-the-industry-by-setting-new-standards-in-sustainable-sourcing-ethical-craftsmanship-and-environmental-impact-our-vision-is-to-elevate-recycled-silver-into-a-symbol-of-responsible-elegance-influencing-the-future-of-jewelry-and-protecting-our-planet {
            max-width: 100%;
            margin-right: 7px;
          }
        }
        .our-story {
          margin-top: 50px;
          font: 800 30px Raleway, sans-serif;
          color: silver;
        }
        @media (max-width: 991px) {
          .our-story {
            margin-top: 40px;
          }
        }
        .about-div-17 {
          box-shadow: 0px 0px 4px rgba(192, 192, 192, 1);
          margin-top: 20px;
          width: 1340px;
          max-width: 100%;
          height: 0px;
        }
        .in-a-world-where-waste-often-goes-unnoticed-two-passionate-young-women-embarked-on-a-journey-fueled-by-curiosity-and-a-shared-commitment-to-sustainability-what-began-as-an-environmental-project-sparked-an-exploration-into-the-potential-of-discarded-materials-leading-us-to-the-hidden-treasure-within-used-x-ray-films-their-precious-silver-content-through-countless-experiments-and-innovative-techniques-we-discovered-the-best-eco-friendly-methods-to-extract-silver-from-various-discarded-wastes-with-remarkable-purity-this-scientific-breakthrough-ignited-a-dream-to-transform-this-high-quality-recycled-silver-into-exquisite-jewelry-that-embodies-both-luxury-and-environmental-consciousness {
          color: rgba(255, 255, 255, 1);
          margin-top: 97px;
          font: 400 25px Lato, sans-serif;
        }
        @media (max-width: 991px) {
          .in-a-world-where-waste-often-goes-unnoticed-two-passionate-young-women-embarked-on-a-journey-fueled-by-curiosity-and-a-shared-commitment-to-sustainability-what-began-as-an-environmental-project-sparked-an-exploration-into-the-potential-of-discarded-materials-leading-us-to-the-hidden-treasure-within-used-x-ray-films-their-precious-silver-content-through-countless-experiments-and-innovative-techniques-we-discovered-the-best-eco-friendly-methods-to-extract-silver-from-various-discarded-wastes-with-remarkable-purity-this-scientific-breakthrough-ignited-a-dream-to-transform-this-high-quality-recycled-silver-into-exquisite-jewelry-that-embodies-both-luxury-and-environmental-consciousness {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .about-div-18 {
          display: flex;
          margin-top: 38px;
          width: 842px;
          max-width: 100%;
          gap: 40px 100px;
          font-family: Raleway, sans-serif;
          flex-wrap: wrap;
        }
        .about-div-19 {
          display: flex;
          flex-direction: column;
          align-items: center;
          flex: 1;
        }
        .about-img-5 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 100%;
          align-self: stretch;
        }
        .donia-al-bitar {
          font-size: 25px;
          font-weight: 800;
          margin-top: 15px;
        }
        .co-founder {
          font-size: 20px;
          font-weight: 500;
          margin-top: 5px;
        }
        .about-about-div-20 {
          display: flex;
          flex-direction: column;
          align-items: center;
          flex: 1;
        }
        .about-img-6 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 100%;
          align-self: stretch;
        }
        .abir-al-bitar {
          font-size: 25px;
          font-weight: 800;
          margin-top: 15px;
        }
        .co-founder-2 {
          font-size: 20px;
          font-weight: 500;
          margin: 5px 0 0 17px;
        }
        .our-project-caught-the-attention-of-various-competitions-where-our-vision-of-marrying-elegance-with-sustainability-resonated-with-many-encouraged-by-the-accolades-and-support-we-took-a-bold-step-forward-turning-our-passion-into-a-thriving-business-today-argentum-stands-as-a-testament-to-our-journey-a-brand-that-weaves-together-the-beauty-of-craftsmanship-with-a-deep-respect-for-the-earth-each-piece-we-create-tells-a-story-of-transformation-innovation-and-a-commitment-to-a-brighter-greener-future {
          color: rgba(255, 255, 255, 1);
          margin-top: 38px;
          font: 400 25px Lato, sans-serif;
        }
        @media (max-width: 991px) {
          .our-project-caught-the-attention-of-various-competitions-where-our-vision-of-marrying-elegance-with-sustainability-resonated-with-many-encouraged-by-the-accolades-and-support-we-took-a-bold-step-forward-turning-our-passion-into-a-thriving-business-today-argentum-stands-as-a-testament-to-our-journey-a-brand-that-weaves-together-the-beauty-of-craftsmanship-with-a-deep-respect-for-the-earth-each-piece-we-create-tells-a-story-of-transformation-innovation-and-a-commitment-to-a-brighter-greener-future {
            max-width: 100%;
          }
        }
        .our-goals-sd-gs {
          margin-top: 50px;
          font: 800 30px Raleway, sans-serif;
          color: silver;
        }
        @media (max-width: 991px) {
          .our-goals-sd-gs {
            margin-top: 40px;
          }
        }
        .about-div-21 {
          box-shadow: 0px 0px 4px rgba(192, 192, 192, 1);
          margin-top: 20px;
          width: 1340px;
          max-width: 100%;
          height: 0px;
        }
        .about-div-22 {
          margin-top: 132px;
          width: 100%;
          max-width: 1330px;
        }
        @media (max-width: 991px) {
          .about-div-22 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .about-div-23 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .about-div-23 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-7 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 25%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-7 {
            width: 100%;
          }
        }
        .about-div-24 {
          border-radius: 17px;
          background-color: rgba(192, 192, 192, 1);
          display: flex;
          flex-grow: 1;
          padding-bottom: 17px;
          flex-direction: column;
          overflow: hidden;
          color: rgba(28, 28, 28, 1);
          text-align: center;
          width: 100%;
          font: 400 15px Lato, sans-serif;
        }
        @media (max-width: 991px) {
          .about-div-24 {
            margin-top: 30px;
          }
        }
        .about-img-7 {
          aspect-ratio: 1.62;
          object-fit: contain;
          object-position: center;
          width: 100%;
        }
        .we-ensure-that-every-piece-of-jewelry-is-crafted-from-recycled-materials-reducing-waste-and-promoting-circular-economy-practices {
          align-self: center;
          margin-top: 14px;
        }
        .column-8 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 25%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-8 {
            width: 100%;
          }
        }
        .about-div-25 {
          border-radius: 17px;
          background-color: rgba(192, 192, 192, 1);
          display: flex;
          flex-grow: 1;
          padding-bottom: 34px;
          flex-direction: column;
          overflow: hidden;
          color: rgba(28, 28, 28, 1);
          text-align: center;
          width: 100%;
          font: 400 15px Lato, sans-serif;
        }
        @media (max-width: 991px) {
          .about-div-25 {
            margin-top: 30px;
          }
        }
        .about-img-8 {
          aspect-ratio: 1.62;
          object-fit: contain;
          object-position: center;
          width: 100%;
        }
        .through-reduced-industrial-waste-and-eco-friendly-production-we-contribute-to-mitigating-environmental-impact {
          align-self: center;
          margin-top: 15px;
        }
        .column-9 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 25%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-9 {
            width: 100%;
          }
        }
        .about-div-26 {
          border-radius: 17px;
          background-color: rgba(192, 192, 192, 1);
          display: flex;
          flex-grow: 1;
          padding-bottom: 34px;
          flex-direction: column;
          overflow: hidden;
          color: rgba(28, 28, 28, 1);
          text-align: center;
          width: 100%;
          font: 400 15px Lato, sans-serif;
        }
        @media (max-width: 991px) {
          .about-div-26 {
            margin-top: 30px;
          }
        }
        .about-img-9 {
          aspect-ratio: 1.62;
          object-fit: contain;
          object-position: center;
          width: 100%;
        }
        .by-minimizing-harmful-waste-that-can-pollute-oceans-we-help-protect-marine-ecosystems-from-industrial-contamination {
          align-self: center;
          margin-top: 15px;
        }
        .column-10 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 25%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-10 {
            width: 100%;
          }
        }
        .about-div-27 {
          border-radius: 17px;
          background-color: rgba(192, 192, 192, 1);
          display: flex;
          flex-grow: 1;
          padding-bottom: 34px;
          flex-direction: column;
          overflow: hidden;
          color: rgba(28, 28, 28, 1);
          text-align: center;
          width: 100%;
          font: 400 15px Lato, sans-serif;
        }
        @media (max-width: 991px) {
          .about-div-27 {
            margin-top: 30px;
          }
        }
        .about-img-10 {
          aspect-ratio: 1.62;
          object-fit: contain;
          object-position: center;
          width: 100%;
        }
        .our-sustainable-practices-protect-terrestrial-ecosystems-and-promote-responsible-use-of-natural-resources {
          align-self: center;
          margin-top: 15px;
          width: 282px;
        }
        .our-contribution-to-the-environment-so-far {
          margin-top: 50px;
          font: 800 30px Raleway, sans-serif;
          color: silver;
        }
        @media (max-width: 991px) {
          .our-contribution-to-the-environment-so-far {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .about-div-28 {
          box-shadow: 0px 0px 4px rgba(192, 192, 192, 1);
          margin-top: 20px;
          width: 1340px;
          max-width: 100%;
          height: 0px;
        }
        .about-div-29 {
          margin-top: 30px;
          width: 100%;
          max-width: 1340px;
        }
        @media (max-width: 991px) {
          .about-div-29 {
            max-width: 100%;
          }
        }
        .about-about-div-30 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .about-about-div-30 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-11 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-11 {
            width: 100%;
          }
        }
        .about-div-31 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .about-div-31 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .our-environmental-impact {
          align-self: start;
          font: 800 40px Raleway, sans-serif;
          color: silver;
        }
        @media (max-width: 991px) {
          .our-environmental-impact {
            max-width: 100%;
          }
        }
        .since-our-inception-argentum-has-recycled-over-500-kilograms-of-silver-from-x-ray-films-and-industrial-waste-saving-significant-amounts-of-harmful-material-from-landfills-by-upcycling-this-waste-into-beautiful-jewelry-we-have-reduced-the-environmental-footprint-of-silver-extraction-while-promoting-a-culture-of-sustainability-370-kilograms-of-silver-recycled-250-x-ray-films-saved-from-landfills-150-pieces-of-sustainable-jewelry-sold {
          color: rgba(255, 255, 255, 1);
          margin-top: 29px;
          font: 400 25px Lato, sans-serif;
        }
        @media (max-width: 991px) {
          .since-our-inception-argentum-has-recycled-over-500-kilograms-of-silver-from-x-ray-films-and-industrial-waste-saving-significant-amounts-of-harmful-material-from-landfills-by-upcycling-this-waste-into-beautiful-jewelry-we-have-reduced-the-environmental-footprint-of-silver-extraction-while-promoting-a-culture-of-sustainability-370-kilograms-of-silver-recycled-250-x-ray-films-saved-from-landfills-150-pieces-of-sustainable-jewelry-sold {
            max-width: 100%;
          }
        }
        .column-12 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-12 {
            width: 100%;
          }
        }
        .about-img-11 {
          aspect-ratio: 1.38;
          object-fit: contain;
          object-position: center;
          width: 100%;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .about-img-11 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .our-certifications {
          margin-top: 50px;
          font: 800 30px Raleway, sans-serif;
          color: silver;
        }
        @media (max-width: 991px) {
          .our-certifications {
            margin-top: 40px;
          }
        }
        .about-div-32 {
          box-shadow: 0px 0px 4px rgba(192, 192, 192, 1);
          margin-top: 20px;
          width: 1340px;
          max-width: 100%;
          height: 0px;
        }
        .about-img-12 {
          aspect-ratio: 5.81;
          object-fit: contain;
          object-position: center;
          width: 100%;
          margin-top: 128px;
          max-width: 1340px;
        }
        @media (max-width: 991px) {
          .about-img-12 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .feedback {
          margin-top: 50px;
          font: 800 30px Raleway, sans-serif;
        }
        @media (max-width: 991px) {
          .feedback {
            margin-top: 40px;
          }
        }
        .about-div-33 {
          box-shadow: 0px 0px 4px rgba(192, 192, 192, 1);
          margin-top: 20px;
          width: 1340px;
          max-width: 100%;
          height: 0px;
        }
        .about-div-34 {
          display: flex;
          margin-top: 30px;
          width: 100%;
          max-width: 1340px;
          align-items: center;
          gap: 20px;
          justify-content: space-between;
        }
        @media (max-width: 991px) {
          .about-div-34 {
            max-width: 100%;
          }
        }
        .about-img-13 {
          aspect-ratio: 0.65;
          object-fit: contain;
          object-position: center;
          width: 31px;
          align-self: stretch;
          margin: auto 0;
        }
        .about-div-35 {
          align-self: stretch;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        @media (max-width: 991px) {
          .about-div-35 {
            max-width: 100%;
          }
        }
        .about-div-36 {
          border-radius: 10px;
          background-color: rgba(255, 255, 255, 1);
          box-shadow: 2px 6px 5px rgba(0, 0, 0, 0.25);
          align-self: stretch;
          display: flex;
          flex-direction: column;
          overflow: hidden;
          text-align: center;
          padding: 18px 26px 89px;
        }
        @media (max-width: 991px) {
          .about-div-36 {
            max-width: 100%;
            padding: 0 20px;
          }
        }
        .about-div-37 {
          align-self: center;
          display: flex;
          width: 594px;
          max-width: 100%;
          align-items: start;
          gap: 20px;
          font-family: Raleway, sans-serif;
          flex-wrap: wrap;
          justify-content: space-between;
        }
        .about-img-14 {
          aspect-ratio: 1.37;
          object-fit: contain;
          object-position: center;
          width: 63px;
        }
        .about-div-38 {
          display: flex;
          flex-direction: column;
        }
        .liam-carter {
          color: rgba(107, 175, 146, 1);
          font-size: 15px;
          font-weight: 600;
          align-self: center;
        }
        .exceptional-quality-and-service {
          color: rgba(28, 28, 28, 1);
          font-size: 25px;
          font-weight: 500;
        }
        .about-img-15 {
          aspect-ratio: 1.37;
          object-fit: contain;
          object-position: center;
          width: 63px;
        }
        .argentums-silver-products-are-truly-top-notch-the-craftsmanship-is-impeccable-and-the-customer-service-team-was-so-helpful-in-guiding-my-investment-highly-recommend-for-anyone-looking-to-invest-in-quality {
          color: rgba(0, 0, 0, 1);
          margin-top: 24px;
          font: 500 20px Lato, sans-serif;
        }
        @media (max-width: 991px) {
          .argentums-silver-products-are-truly-top-notch-the-craftsmanship-is-impeccable-and-the-customer-service-team-was-so-helpful-in-guiding-my-investment-highly-recommend-for-anyone-looking-to-invest-in-quality {
            max-width: 100%;
          }
        }
        .about-img-16 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 150px;
          border-radius: 100px;
          box-shadow: 2px 6px 5px rgba(0, 0, 0, 0.25);
          z-index: 10;
          margin-top: -75px;
          max-width: 100%;
        }
        .about-div-39 {
          display: flex;
          flex-direction: column;
          border-radius: 20px;
          position: relative;
          margin-top: 22px;
          min-height: 55px;
          width: 100%;
          align-items: center;
          gap: 10px;
          overflow: hidden;
          color: rgba(28, 28, 28, 1);
          justify-content: center;
          padding: 11px 12px 11px 11px;
          font: 700 15px Poppins, sans-serif;
        }
        .about-img-17 {
          position: absolute;
          inset: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
        .add-feedback {
          position: relative;
          border-radius: 20px;
          background-color: rgba(192, 192, 192, 1);
          gap: 10px;
          overflow: hidden;
          margin: auto 0;
          padding: 13px 25px;
        }
        @media (max-width: 991px) {
          .add-feedback {
            padding: 0 20px;
          }
        }
        .about-img-18 {
          aspect-ratio: 0.63;
          object-fit: contain;
          object-position: center;
          width: 31px;
          align-self: stretch;
          margin: auto 0;
        }
        .about-about-div-40 {
          background-color: rgba(192, 192, 192, 0.6);
          align-self: stretch;
          margin-top: 42px;
          width: 100%;
          overflow: hidden;
          padding: 20px 49px 31px;
        }
        @media (max-width: 991px) {
          .about-about-div-40 {
            max-width: 100%;
            margin-top: 40px;
            padding: 0 20px;
          }
        }
        .about-div-41 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .about-div-41 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-13 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 36%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-13 {
            width: 100%;
          }
        }
        .about-div-42 {
          display: flex;
          margin-top: 49px;
          flex-direction: column;
          align-items: center;
          color: rgba(228, 226, 221, 1);
          white-space: nowrap;
          font: 600 15px Raleway, sans-serif;
        }
        @media (max-width: 991px) {
          .about-div-42 {
            margin-top: 40px;
            white-space: initial;
          }
        }
        .about-div-43 {
          align-self: stretch;
          height: 1px;
          border: 1px solid rgba(255, 255, 255, 1);
        }
        .home {
          margin-top: 38px;
        }
        .shop-2 {
          margin-top: 15px;
        }
        .about {
          margin-top: 15px;
        }
        .community-2 {
          margin-top: 15px;
        }
        .column-14 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 27%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-14 {
            width: 100%;
          }
        }
        .about-div-44 {
          display: flex;
          width: 100%;
          flex-direction: column;
          align-items: center;
        }
        @media (max-width: 991px) {
          .about-div-44 {
            margin-top: 40px;
          }
        }
        .about-img-19 {
          aspect-ratio: 0.8;
          object-fit: contain;
          object-position: center;
          width: 47px;
        }
        .argentum {
          color: rgba(228, 226, 221, 1);
          margin-top: 10px;
          font: 400 23px FONTSPRING DEMO - Quincy CF Text, -apple-system, Roboto,
            Helvetica, sans-serif;
        }
        .about-about-img-20 {
          aspect-ratio: 8.55;
          object-fit: contain;
          object-position: center;
          width: 100%;
          align-self: stretch;
          margin-top: 55px;
        }
        @media (max-width: 991px) {
          .about-about-img-20 {
            margin-top: 40px;
          }
        }
        .about-div-45 {
          margin-top: 40px;
          width: 1px;
          height: 38px;
          border: 1px solid rgba(255, 255, 255, 1);
        }
        .weekly-newsletter {
          color: rgba(228, 226, 221, 1);
          margin-top: 20px;
          font: 600 15px Raleway, sans-serif;
        }
        .about-div-46 {
          border-radius: 15px;
          align-self: stretch;
          display: flex;
          margin-top: 10px;
          width: 100%;
          gap: 20px;
          overflow: hidden;
          font-size: 12px;
          justify-content: space-between;
          padding: 0 1px 0 15px;
          border: 1px solid rgba(192, 192, 192, 1);
        }
        @media (max-width: 991px) {
          .about-div-46 {
            margin: 0 6px 0 7px;
          }
        }
        .email-address {
          color: rgba(228, 226, 221, 0.6);
          font-family: Lato, sans-serif;
          font-weight: 500;
          margin: auto 0;
        }
        .subscribe {
          align-self: stretch;
          background-color: rgba(192, 192, 192, 1);
          min-height: 33px;
          gap: 10px;
          font-family: Poppins, sans-serif;
          color: rgba(28, 28, 28, 0.7);
          font-weight: 700;
          white-space: nowrap;
          padding: 8px 20px;
        }
        @media (max-width: 991px) {
          .subscribe {
            white-space: initial;
          }
        }
        .column-15 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 36%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-15 {
            width: 100%;
          }
        }
        .about-div-47 {
          display: flex;
          margin-top: 49px;
          flex-direction: column;
          align-items: center;
          color: rgba(228, 226, 221, 1);
          font: 600 15px Raleway, sans-serif;
        }
        @media (max-width: 991px) {
          .about-div-47 {
            margin-top: 40px;
          }
        }
        .about-div-48 {
          align-self: stretch;
          height: 1px;
          border: 1px solid rgba(255, 255, 255, 1);
        }
        .info-argentumco-com {
          margin-top: 38px;
        }
        .961-78901035 {
          margin-top: 15px;
        }
        .961-71669247 {
          margin-top: 15px;
        }
        .beirut-lebanon {
          margin-top: 15px;
        }
      `}</style>{" "}
    </>
  );
}

export default About;
