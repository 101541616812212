import * as React from "react";
import Events from "./ComEvents";
import Collection from "./Collection";
import Sellers from "./Sellers";
import Navbar from "./nav";

function Home() {
  return (
    <>
      <Navbar />
      <div className="home-page">
        <div className="div-6">
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/ef9fa718a465db470e4fa7493aebd27dc9910e2f901ac2694b621d5c3a6df2c2?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/ef9fa718a465db470e4fa7493aebd27dc9910e2f901ac2694b621d5c3a6df2c2?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ef9fa718a465db470e4fa7493aebd27dc9910e2f901ac2694b621d5c3a6df2c2?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/ef9fa718a465db470e4fa7493aebd27dc9910e2f901ac2694b621d5c3a6df2c2?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/ef9fa718a465db470e4fa7493aebd27dc9910e2f901ac2694b621d5c3a6df2c2?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ef9fa718a465db470e4fa7493aebd27dc9910e2f901ac2694b621d5c3a6df2c2?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/ef9fa718a465db470e4fa7493aebd27dc9910e2f901ac2694b621d5c3a6df2c2?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/ef9fa718a465db470e4fa7493aebd27dc9910e2f901ac2694b621d5c3a6df2c2?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
            className="img-2"
          />
          <div className="div-7">
            <div className="argentum">ARGENTUM</div>
            <div className="turning-silver-into-green">
              <span style={{ fontWeight: 500 }}>Turning </span>
              <span
                style={{ fontWeight: 500, color: "rgba(255, 255, 255, 1)" }}
              >
                Silver
              </span>
              <span style={{ fontWeight: 500 }}> into </span>
              <span
                style={{ fontWeight: 800, color: "rgba(107, 175, 146, 1)" }}
              >
                Green
              </span>
            </div>
            <div className="argentum-is-a-pioneer-in-transforming-waste-into-stunning-silver-jewelry-discover-how-we-create-beauty-from-what-others-discard-join-the-movement-towards-a-sustainable-future">
              Argentum is a pioneer in transforming waste into stunning silver
              jewelry. Discover how we create beauty from what others
              discard-join the movement towards a sustainable future.
            </div>
            <div className="join-now">Join Now</div>
          </div>
        </div>
        <div className="div-8">
          <div className="div-9">
            <div className="best-sellers">Best Sellers</div>
            <div className="div-10">
              <Sellers />
            </div>
          </div>
          <div className="div-11">
            <div className="our-new-collection">Our New Collection</div>
            <div className="div-12" />
            <div className="div-13">
              <div className="div-14">
                <div className="column-3">
                  <div className="div-15">
                    <div className="introducing-our-new-collection-where-sustainability-meets-luxury">
                      Introducing Our New Collection:
                      <br />
                      Where Sustainability Meets Luxury.
                    </div>
                    <div className="div-16">
                      <div className="each-piece-tells-a-story-of-transformation-crafted-from-recycled-silver-designed-with-passion-from-discarded-materials-to-exquisite-creations-every-piece-is-a-testament-to-innovation-preserving-our-planets-resources-while-elevating-your-style">
                        Each piece tells a story of transformation-crafted from
                        recycled silver, designed with passion. From discarded
                        materials to exquisite creations, every piece is a
                        testament to innovation, preserving our planet’s
                        resources while elevating your style.
                      </div>
                      <div className="div-17">
                        <div className="div-18">
                          <div className="shop-the-collection">
                            Shop the Collection
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column-4">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/a7a0ad2597341958e873bd23472a9c964bab0456da3cd8e237c749e3dde14058?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/a7a0ad2597341958e873bd23472a9c964bab0456da3cd8e237c749e3dde14058?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a7a0ad2597341958e873bd23472a9c964bab0456da3cd8e237c749e3dde14058?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/a7a0ad2597341958e873bd23472a9c964bab0456da3cd8e237c749e3dde14058?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/a7a0ad2597341958e873bd23472a9c964bab0456da3cd8e237c749e3dde14058?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a7a0ad2597341958e873bd23472a9c964bab0456da3cd8e237c749e3dde14058?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/a7a0ad2597341958e873bd23472a9c964bab0456da3cd8e237c749e3dde14058?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/a7a0ad2597341958e873bd23472a9c964bab0456da3cd8e237c749e3dde14058?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                    className="img-4"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="div-19">
            <div className="upcoming-event">Upcoming Event</div>
            <Events />
          </div>
          <div className="div-21">
            <div className="our-new-collection-2">Our New Collection</div>
            <div className="div-22">
              <Collection />
            </div>
          </div>
          <div className="div-23">
            <div className="contact-us">Contact Us</div>
            <div className="div-24" />
            <div className="get-in-touch-with-us">Get in Touch with Us</div>
            <div className="have-questions-or-want-to-learn-more-about-our-sustainable-jewelry-collections-wed-love-to-hear-from-you-reach-out-to-us-today">
              Have questions or want to learn more about our sustainable jewelry
              collections? We'd love to hear
              <br />
              from you. Reach out to us today
            </div>
            <div className="div-25">
              <div className="div-26">
                <div className="column-5">
                  <div className="div-27">
                    <div className="full-name">Full Name</div>
                    <div className="name-example">Name Example</div>
                    <div className="companys-name-if-applicable">
                      Company’s Name (If Applicable)
                    </div>
                    <div className="name-example">Name Example</div>
                  </div>
                </div>
                <div className="column-6">
                  <div className="div-28">
                    <div className="email-address">Email Address</div>
                    <div className="name-example">Name Example</div>
                    <div className="companys-email-address-if-applicable">
                      Company’s Email Address (If Applicable)
                    </div>
                    <div className="name-example">Name Example</div>
                  </div>
                </div>
                <div className="column-7">
                  <div className="div-29">
                    <div className="phone-number">Phone Number</div>
                    <div className="name-example">Name Example</div>
                    <div className="companys-phone-number-if-applicable">
                      Company’s Phone Number (If Applicable)
                    </div>
                    <div className="name-example">Name Example</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="message">Message</div>
            <div className="message-example">Message Example</div>
            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/1fd25fa80421c374624e159befd3975808cef70b38a3aebf75ac562d321adddd?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/1fd25fa80421c374624e159befd3975808cef70b38a3aebf75ac562d321adddd?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1fd25fa80421c374624e159befd3975808cef70b38a3aebf75ac562d321adddd?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/1fd25fa80421c374624e159befd3975808cef70b38a3aebf75ac562d321adddd?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/1fd25fa80421c374624e159befd3975808cef70b38a3aebf75ac562d321adddd?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1fd25fa80421c374624e159befd3975808cef70b38a3aebf75ac562d321adddd?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/1fd25fa80421c374624e159befd3975808cef70b38a3aebf75ac562d321adddd?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/1fd25fa80421c374624e159befd3975808cef70b38a3aebf75ac562d321adddd?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
              className="img-5"
            />
          </div>
        </div>
        <div className="div-30">
          <div className="div-31">
            <div className="column-8">
              <div className="div-32">
                <div className="div-33" />
                <div className="home">Home</div>
                <div className="shop-2">Shop</div>
                <div className="about">About</div>
                <div className="community-2">Community</div>
              </div>
            </div>
            <div className="column-9">
              <div className="div-34">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/5a808a74bc2f46868453e97a9455d52f31c8a16034c88587e8e67a518df76e18?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                  className="img-6"
                />
                <div className="argentum-2">ARGENTUM</div>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/e6fdac41435667c1e8acf5467dd79aeedec8b449fb3598f7df6756c0e0736b9a?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                  className="img-7"
                />
                <div className="div-35" />
                <div className="weekly-newsletter">Weekly Newsletter</div>
                <div className="div-36">
                  <div className="email-address-2">Email Address</div>
                  <div className="subscribe">Subscribe</div>
                </div>
              </div>
            </div>
            <div className="column-10">
              <div className="div-37">
                <div className="div-38" />
                <div className="info-argentumco-com">info@argentumco.com</div>
                <div className="961-78901035">+961 78901035</div>
                <div className="961-71669247">+961 71669247</div>
                <div className="beirut-lebanon">Beirut - Lebanon </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      <style jsx>{`
        .home-page {
          background: var(
            --Linear-Background,
            linear-gradient(117deg, #1c1c1c 0%, rgba(192, 192, 192, 0.5) 100%)
          );
          background-color: var(
            --Linear-Background,
            linear-gradient(117deg, #1c1c1c 0%, rgba(192, 192, 192, 0.5) 100%)
          );
          display: flex;
          flex-direction: column;
          overflow: hidden;
          padding: 0px;
        }
        .div {
          background-color: rgba(28, 28, 28, 1);
          box-shadow: 0px 6px 8px rgba(192, 192, 192, 0.5);
          width: 100%;
          overflow: hidden;
        }
        @media (max-width: 991px) {
          .div {
            max-width: 100%;
            padding: 0 20px;
          }
        }
        .div-2 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .div-2 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 83%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column {
            width: 100%;
          }
        }
        .div-3 {
          display: flex;
          width: 100%;
          align-items: center;
          gap: 40px 100px;
          color: rgba(192, 192, 192, 0.6);
          justify-content: space-between;
          flex-wrap: wrap;
          font: 700 20px Raleway, sans-serif;
        }
        @media (max-width: 991px) {
          .div-3 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .ag-logo-2 {
          align-self: stretch;
          display: flex;
          width: 93px;
          height: 63px;
          margin: auto 0;
        }
        .div-4 {
          align-self: stretch;
          display: flex;
          min-width: 240px;
          align-items: center;
          gap: 40px 70px;
          justify-content: start;
          flex-wrap: wrap;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .div-4 {
            max-width: 100%;
          }
        }
        .home-page-2 {
          color: rgba(192, 192, 192, 1);
          align-self: stretch;
          margin: auto 0;
        }
        .shop {
          align-self: stretch;
          margin: auto 0;
        }
        .community {
          align-self: stretch;
          margin: auto 0;
        }
        .about-us {
          align-self: stretch;
          margin: auto 0;
        }
        .column-2 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 17%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-2 {
            width: 100%;
          }
        }
        .div-5 {
          display: flex;
          width: 100%;
          gap: 15px;
          align-self: stretch;
          color: rgba(28, 28, 28, 1);
          margin: auto 0;
          font: 700 15px Poppins, sans-serif;
        }
        @media (max-width: 991px) {
          .div-5 {
            margin-top: 40px;
          }
        }
        .img {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 49px;
          border-radius: 99px;
        }
        .log-in {
          align-self: stretch;
          border-radius: 20px;
          gap: 10px;
          overflow: hidden;
          padding: 13px 40px;
        }
        @media (max-width: 991px) {
          .log-in {
            padding: 0 20px;
          }
        }
        .div-6 {
          display: flex;
          flex-direction: column;
          position: relative;
          min-height: 530px;
          width: 100%;
          color: rgba(255, 255, 255, 1);
          font: 700 20px Raleway, sans-serif;
        }
        @media (max-width: 991px) {
          .div-6 {
            max-width: 100%;
          }
        }
        .img-2 {
          position: absolute;
          inset: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
        .div-7 {
          position: relative;
          background-color: rgba(0, 0, 0, 0.1);
          display: flex;
          width: 100%;
          flex-direction: column;
          overflow: hidden;
          align-items: start;
          padding: 112px 50px;
        }
        @media (max-width: 991px) {
          .div-7 {
            max-width: 100%;
            padding: 100px 20px;
          }
        }
        .argentum {
          font-weight: 800;
          font-size: 90px;
          font-family: "FONTSPRING DEMO - Quincy CF Extra Bold", -apple-system,
            Roboto, Helvetica, sans-serif;
        }
        @media (max-width: 991px) {
          .argentum {
            max-width: 100%;
            font-size: 40px;
          }
        }
        .turning-silver-into-green {
          font-size: 40px;
          letter-spacing: 1.6px;
          margin-top: 15px;
        }
        @media (max-width: 991px) {
          .turning-silver-into-green {
            max-width: 100%;
          }
        }
        .argentum-is-a-pioneer-in-transforming-waste-into-stunning-silver-jewelry-discover-how-we-create-beauty-from-what-others-discard-join-the-movement-towards-a-sustainable-future {
          margin-top: 20px;
          width: 539px;
        }
        @media (max-width: 991px) {
          .argentum-is-a-pioneer-in-transforming-waste-into-stunning-silver-jewelry-discover-how-we-create-beauty-from-what-others-discard-join-the-movement-towards-a-sustainable-future {
            max-width: 100%;
          }
        }
        .join-now {
          border-radius: 20px;
          background-color: rgba(192, 192, 192, 1);
          gap: 10px;
          overflow: hidden;
          font-family: Poppins, sans-serif;
          color: rgba(28, 28, 28, 1);
          margin: 20px 0 -22px;
          padding: 13px 40px;
        }
        @media (max-width: 991px) {
          .join-now {
            margin-bottom: 10px;
            padding: 0 20px;
          }
        }
        .div-8 {
          align-self: center;
          display: flex;
          margin-top: 50px;
          width: 100%;
          max-width: 1343px;
          flex-direction: column;
          align-items: end;
          justify-content: start;
        }
        @media (max-width: 991px) {
          .div-8 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-9 {
          display: flex;
          width: 100%;
          padding-bottom: 418px;
          flex-direction: column;
          font: 800 30px Raleway, sans-serif;
        }
        @media (max-width: 991px) {
          .div-9 {
            padding-bottom: 100px;
          }
        }
        .best-sellers {
          align-self: center;
          color: white;
        }
        .div-10 {
          box-shadow: 0px 0px 4px rgba(192, 192, 192, 1);
          height: 0px;
          margin: 20px 0 -84px;
        }
        @media (max-width: 991px) {
          .div-10 {
            max-width: 100%;
            margin-bottom: 10px;
          }
        }
        .div-11 {
          border-radius: 0px 0px 0px 0px;
          display: flex;
          margin-top: 50px;
          width: 100%;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-11 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .our-new-collection {
          align-self: center;
          font: 800 30px Raleway, sans-serif;
          color: white;
        }
        .div-12 {
          box-shadow: 0px 0px 4px rgba(192, 192, 192, 1);
          margin-top: 20px;
          width: 100%;
          height: 0px;
        }
        .div-13 {
          margin-top: 30px;
        }
        @media (max-width: 991px) {
          .div-13 {
            max-width: 100%;
          }
        }
        .div-14 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .div-14 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-3 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-3 {
            width: 100%;
          }
        }
        .div-15 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-15 {
            max-width: 100%;
            margin-top: 37px;
          }
        }
        .introducing-our-new-collection-where-sustainability-meets-luxury {
          color: rgba(192, 192, 192, 1);
          font: 700 40px Raleway, sans-serif;
        }
        @media (max-width: 991px) {
          .introducing-our-new-collection-where-sustainability-meets-luxury {
            max-width: 100%;
          }
        }
        .div-16 {
          display: flex;
          margin-top: 20px;
          flex-direction: column;
          justify-content: start;
        }
        @media (max-width: 991px) {
          .div-16 {
            max-width: 100%;
          }
        }
        .each-piece-tells-a-story-of-transformation-crafted-from-recycled-silver-designed-with-passion-from-discarded-materials-to-exquisite-creations-every-piece-is-a-testament-to-innovation-preserving-our-planets-resources-while-elevating-your-style {
          color: rgba(192, 192, 192, 1);
          font: 500 25px Lato, sans-serif;
        }
        @media (max-width: 991px) {
          .each-piece-tells-a-story-of-transformation-crafted-from-recycled-silver-designed-with-passion-from-discarded-materials-to-exquisite-creations-every-piece-is-a-testament-to-innovation-preserving-our-planets-resources-while-elevating-your-style {
            max-width: 100%;
          }
        }
        .div-17 {
          display: flex;
          margin-top: 20px;
          width: 236px;
          max-width: 100%;
          flex-direction: column;
          color: rgba(28, 28, 28, 1);
          font: 700 15px Poppins, sans-serif;
        }
        .div-18 {
          display: flex;
          flex-direction: column;
          border-radius: 20px;
          position: relative;
          z-index: 10;
          aspect-ratio: 3.549;
          min-height: 55px;
          width: 100%;
          align-items: center;
          gap: 10px;
          overflow: hidden;
          justify-content: center;
          margin: -8px 0;
          padding: 11px;
        }
        @media (max-width: 991px) {
          .div-18 {
            margin-right: -8px;
          }
        }
        .img-3 {
          position: absolute;
          inset: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
        .shop-the-collection {
          position: relative;
          align-self: stretch;
          border-radius: 20px;
          background-color: rgba(192, 192, 192, 1);
          gap: 10px;
          overflow: hidden;
          margin: auto 0;
          padding: 13px 40px;
        }
        @media (max-width: 991px) {
          .shop-the-collection {
            padding: 0 20px;
          }
        }
        .column-4 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-4 {
            width: 100%;
          }
        }
        .img-4 {
          aspect-ratio: 1.77;
          object-fit: contain;
          object-position: center;
          width: 100%;
          border-radius: 20px;
        }
        @media (max-width: 991px) {
          .img-4 {
            max-width: 100%;
            margin-top: 37px;
            border-radius: 20px;
          }
        }
        .div-19 {
          display: flex;
          margin-top: 50px;
          width: 100%;
          flex-direction: column;
          font: 800 30px Raleway, sans-serif;
        }
        @media (max-width: 991px) {
          .div-19 {
            padding-bottom: 100px;
            margin-top: 40px;
          }
        }
        .upcoming-event {
          align-self: center;
          color: white;
          margin-bottom: 15px;
        }
        .div-20 {
          box-shadow: 0px 0px 4px rgba(192, 192, 192, 1);
          height: 0px;
          margin: 20px 0 -137px;
        }
        @media (max-width: 991px) {
          .div-20 {
            max-width: 100%;
            margin-bottom: 10px;
          }
        }
        .div-21 {
          display: flex;
          margin-top: 50px;
          width: 100%;
          padding-bottom: 411px;
          flex-direction: column;
          font: 800 30px Raleway, sans-serif;
        }
        @media (max-width: 991px) {
          .div-21 {
            padding-bottom: 100px;
            margin-top: 40px;
          }
        }
        .our-new-collection-2 {
          align-self: center;
          color: white;
        }
        .div-22 {
          box-shadow: 0px 0px 4px rgba(192, 192, 192, 1);
          height: 0px;
          margin: 20px 0 -82px;
        }
        @media (max-width: 991px) {
          .div-22 {
            max-width: 100%;
            margin-bottom: 10px;
          }
        }
        .div-23 {
          display: flex;
          margin-top: 50px;
          width: 100%;
          max-width: 1342px;
          flex-direction: column;
          padding: 3px 0;
        }
        @media (max-width: 991px) {
          .div-23 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .contact-us {
          align-self: center;
          font: 800 30px Raleway, sans-serif;
          margin-top: 50px;
          color: white;
        }
        .div-24 {
          box-shadow: 0px 0px 4px rgba(192, 192, 192, 1);
          margin-top: 20px;
          width: 100%;
          height: 0px;
        }
        .get-in-touch-with-us {
          color: rgba(192, 192, 192, 1);
          align-self: start;
          margin-top: 30px;
          font: 800 40px Raleway, sans-serif;
        }
        @media (max-width: 991px) {
          .get-in-touch-with-us {
            margin-left: 2px;
          }
        }
        .have-questions-or-want-to-learn-more-about-our-sustainable-jewelry-collections-wed-love-to-hear-from-you-reach-out-to-us-today {
          color: rgba(192, 192, 192, 1);
          margin: 20px 49px 0 0;
          font: 500 30px Lato, sans-serif;
        }
        @media (max-width: 991px) {
          .have-questions-or-want-to-learn-more-about-our-sustainable-jewelry-collections-wed-love-to-hear-from-you-reach-out-to-us-today {
            max-width: 100%;
            margin-right: 10px;
          }
        }
        .div-25 {
          margin-top: 20px;
        }
        @media (max-width: 991px) {
          .div-25 {
            max-width: 100%;
          }
        }
        .div-26 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .div-26 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-5 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 33%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-5 {
            width: 100%;
          }
        }
        .div-27 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          color: rgba(192, 192, 192, 1);
          font: 500 20px Lato, sans-serif;
        }
        @media (max-width: 991px) {
          .div-27 {
            margin-top: 40px;
          }
        }
        .full-name {
          align-self: start;
        }
        .name-example {
          border-radius: 15px;
          margin-top: 10px;
          overflow: hidden;
          color: rgba(192, 192, 192, 0.6);
          padding: 17px 16px;
          border: 1px solid rgba(192, 192, 192, 1);
        }
        @media (max-width: 991px) {
          .name-example {
            padding-right: 20px;
          }
        }
        .companys-name-if-applicable {
          align-self: start;
          margin-top: 20px;
        }
        .column-6 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 33%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-6 {
            width: 100%;
          }
        }
        .div-28 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          color: rgba(192, 192, 192, 1);
          font: 500 20px Lato, sans-serif;
        }
        @media (max-width: 991px) {
          .div-28 {
            margin-top: 40px;
          }
        }
        .email-address {
          align-self: start;
        }
        .companys-email-address-if-applicable {
          align-self: start;
          margin-top: 20px;
        }
        .column-7 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 33%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-7 {
            width: 100%;
          }
        }
        .div-29 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          color: rgba(192, 192, 192, 1);
          font: 500 20px Lato, sans-serif;
        }
        @media (max-width: 991px) {
          .div-29 {
            margin-top: 40px;
          }
        }
        .phone-number {
          align-self: start;
        }
        .companys-phone-number-if-applicable {
          align-self: start;
          margin-top: 20px;
        }
        .message {
          color: rgba(192, 192, 192, 1);
          align-self: start;
          margin-top: 20px;
          font: 500 20px Lato, sans-serif;
        }
        @media (max-width: 991px) {
          .message {
            margin-left: 2px;
          }
        }
        .message-example {
          border-radius: 15px;
          margin-top: 10px;
          overflow: hidden;
          color: rgba(192, 192, 192, 0.6);
          padding: 17px 16px 126px;
          font: 500 20px Lato, sans-serif;
          border: 1px solid rgba(192, 192, 192, 1);
        }
        @media (max-width: 991px) {
          .message-example {
            max-width: 100%;
            padding: 0 20px 110px 0;
          }
        }
        .img-5 {
          aspect-ratio: 2.29;
          object-fit: contain;
          object-position: center;
          width: 126px;
          border-radius: 20px;
          align-self: center;
          margin-top: 30px;
          max-width: 100%;
        }
        .div-30 {
          background-color: rgba(192, 192, 192, 0.6);
          margin-top: 50px;
          width: 100%;
          overflow: hidden;
          padding: 20px 49px 31px;
        }
        @media (max-width: 991px) {
          .div-30 {
            max-width: 100%;
            margin-top: 40px;
            padding: 0 20px;
          }
        }
        .div-31 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .div-31 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-8 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 36%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-8 {
            width: 100%;
          }
        }
        .div-32 {
          display: flex;
          margin-top: 49px;
          flex-direction: column;
          align-items: center;
          color: rgba(228, 226, 221, 1);
          white-space: nowrap;
          font: 600 15px Raleway, sans-serif;
        }
        @media (max-width: 991px) {
          .div-32 {
            margin-top: 40px;
            white-space: initial;
          }
        }
        .div-33 {
          align-self: stretch;
          height: 1px;
          border: 1px solid rgba(255, 255, 255, 1);
        }
        .home {
          margin-top: 38px;
        }
        .shop-2 {
          margin-top: 15px;
        }
        .about {
          margin-top: 15px;
        }
        .community-2 {
          margin-top: 15px;
        }
        .column-9 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 27%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-9 {
            width: 100%;
          }
        }
        .div-34 {
          display: flex;
          width: 100%;
          flex-direction: column;
          align-items: center;
        }
        @media (max-width: 991px) {
          .div-34 {
            margin-top: 40px;
          }
        }
        .img-6 {
          aspect-ratio: 0.8;
          object-fit: contain;
          object-position: center;
          width: 47px;
        }
        .argentum-2 {
          color: rgba(228, 226, 221, 1);
          margin-top: 10px;
          font: 400 23px FONTSPRING DEMO - Quincy CF Text, -apple-system, Roboto,
            Helvetica, sans-serif;
        }
        .img-7 {
          aspect-ratio: 8.55;
          object-fit: contain;
          object-position: center;
          width: 100%;
          align-self: stretch;
          margin-top: 55px;
        }
        @media (max-width: 991px) {
          .img-7 {
            margin-top: 40px;
          }
        }
        .div-35 {
          margin-top: 40px;
          width: 1px;
          height: 38px;
          border: 1px solid rgba(255, 255, 255, 1);
        }
        .weekly-newsletter {
          color: rgba(228, 226, 221, 1);
          margin-top: 20px;
          font: 600 15px Raleway, sans-serif;
        }
        .div-36 {
          border-radius: 15px;
          align-self: stretch;
          display: flex;
          margin-top: 10px;
          width: 100%;
          gap: 20px;
          overflow: hidden;
          font-size: 12px;
          justify-content: space-between;
          padding: 0 1px 0 15px;
          border: 1px solid rgba(192, 192, 192, 1);
        }
        @media (max-width: 991px) {
          .div-36 {
            margin: 0 6px 0 7px;
          }
        }
        .email-address-2 {
          color: rgba(228, 226, 221, 0.6);
          font-family: Lato, sans-serif;
          font-weight: 500;
          margin: auto 0;
        }
        .subscribe {
          align-self: stretch;
          background-color: rgba(192, 192, 192, 1);
          min-height: 33px;
          gap: 10px;
          font-family: Poppins, sans-serif;
          color: rgba(28, 28, 28, 0.7);
          font-weight: 700;
          white-space: nowrap;
          padding: 8px 20px;
        }
        @media (max-width: 991px) {
          .subscribe {
            white-space: initial;
          }
        }
        .column-10 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 36%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-10 {
            width: 100%;
          }
        }
        .div-37 {
          display: flex;
          margin-top: 49px;
          flex-direction: column;
          align-items: center;
          color: rgba(228, 226, 221, 1);
          font: 600 15px Raleway, sans-serif;
        }
        @media (max-width: 991px) {
          .div-37 {
            margin-top: 40px;
          }
        }
        .div-38 {
          align-self: stretch;
          height: 1px;
          border: 1px solid rgba(255, 255, 255, 1);
        }
        .info-argentumco-com {
          margin-top: 38px;
        }
        .961-78901035 {
          margin-top: 15px;
        }
        .961-71669247 {
          margin-top: 15px;
        }
        .beirut-lebanon {
          margin-top: 15px;
        }
      `}</style>{" "}
    </>
  );
}

export default Home;
