import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./nav.css";

function Navbar() {
  const [showPopup, setShowPopup] = useState(false);

  // Dummy data for silver rate
  const silverRate = "Silver Rate: $24.50 per ounce";

  // Toggle popup
  const togglePopup = () => {
    setShowPopup((prev) => !prev);
  };

  return (
    <>
      <div className="navbar-container">
        <div className="navbar-inner">
          {/* Logo on the left */}
          <div className="navbar-logo">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/573ea9ad90e4128e80b641bf72d5c5599e3bf35d4c79ef617d090ed578c1b073?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
              className="logo-img"
              alt="Logo"
            />
          </div>

          {/* Navigation links in the center */}
          <div className="navbar-links">
            <Link to="/" className="nav-link">
              Home Page
            </Link>
            <Link to="/shop" className="nav-link">
              Shop
            </Link>
            <Link to="/community" className="nav-link">
              Community
            </Link>
            <Link to="/about" className="nav-link">
              About Us
            </Link>
          </div>

          {/* Login and user icon on the right */}
          <div className="navbar-user">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/b4cbb001fa59cefec59c18040728b239317ca2d6a2691a7dc443bebb71ed38f2?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
              className="user-icon"
              alt="User Icon"
              onClick={togglePopup}
            />
            <div className="login-button">Log In</div>
          </div>
        </div>

        {/* Silver rate popup */}
        {showPopup && (
          <div className="popup">
            <p>{silverRate}</p>
            <button onClick={togglePopup} className="close-popup">
              Close
            </button>
          </div>
        )}
      </div>

      <style jsx>{`
        .navbar-container {
          background-color: rgba(28, 28, 28, 1);
          box-shadow: 0px 6px 8px rgba(192, 192, 192, 0.5);
          padding: 10px 50px;
        }
        .navbar-inner {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .navbar-logo img {
          width: 80px;
          height: auto;
        }
        .navbar-links {
          display: flex;
          gap: 30px;
          justify-content: center;
          flex-grow: 1;
        }
        .nav-link {
          color: rgba(192, 192, 192, 1);
          text-decoration: none;
          font-weight: 700;
          font-size: 18px;
        }
        .nav-link:hover {
          color: rgba(255, 255, 255, 1);
        }
        .navbar-user {
          display: flex;
          align-items: center;
          gap: 15px;
        }
        .user-icon {
          width: 40px;
          border-radius: 50%;
          cursor: pointer;
        }
        .login-button {
          background-color: #444;
          color: #fff;
          padding: 10px 20px;
          border-radius: 20px;
          cursor: pointer;
        }
        .login-button:hover {
          background-color: #333;
        }
        .popup {
          position: absolute;
          top: 80px;
          right: 20px;
          background-color: #333;
          color: #fff;
          padding: 15px;
          border-radius: 8px;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
        }
        .close-popup {
          background-color: #555;
          color: #fff;
          border: none;
          padding: 5px 10px;
          cursor: pointer;
          border-radius: 4px;
          margin-top: 10px;
        }
        .close-popup:hover {
          background-color: #444;
        }
      `}</style>
    </>
  );
}

export default Navbar;
