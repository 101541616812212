import React from "react";
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import About from "./About";
import Home from "./Argentum";
import Community from "./Community";
import Shop from "./Shop";

function App() {
  return (
    <Router>
      <div className="App">
      
        {/* Routes */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/community" element={<Community />} />
          <Route path="/shop" element={<Shop />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
