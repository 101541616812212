import * as React from "react";

function Events() {
  return (
    <>
      <div className="event-div">
        <div className="event-div-2">
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c6d8978581fc1a29bae34e8c3e79c7bac570e60a0249b6c73e1a64285dedb647?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6d8978581fc1a29bae34e8c3e79c7bac570e60a0249b6c73e1a64285dedb647?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6d8978581fc1a29bae34e8c3e79c7bac570e60a0249b6c73e1a64285dedb647?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6d8978581fc1a29bae34e8c3e79c7bac570e60a0249b6c73e1a64285dedb647?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6d8978581fc1a29bae34e8c3e79c7bac570e60a0249b6c73e1a64285dedb647?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6d8978581fc1a29bae34e8c3e79c7bac570e60a0249b6c73e1a64285dedb647?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6d8978581fc1a29bae34e8c3e79c7bac570e60a0249b6c73e1a64285dedb647?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6d8978581fc1a29bae34e8c3e79c7bac570e60a0249b6c73e1a64285dedb647?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
            className="event-img"
          />
          <div className="event-div-3">
            <div className="the-red-carpet-launch-of-our-new-collection">
              The Red Carpet Launch of Our New Collection.
            </div>
            <div className="a-night-where-sustainability-shines-be-among-the-first-to-experience-our-latest-designs-while-supporting-a-greener-planet">
              A night where sustainability shines. Be among the first to
              experience our latest designs, while supporting a greener planet
            </div>
            <div className="event-div-4" />
            <div className="oct-30-2024-nov-4-2024-7-00-pm-11-00-pm">
              Oct 30, 2024 - Nov 4, 2024, 7:00 PM - 11:00 PM
            </div>
            <div className="casino-du-liban-jounieh-lebanon">
              Casino du Liban, Jounieh, Lebanon
            </div>
            <div className="event-div-5">
              <div className="rsvp-now">RSVP Now</div>
            </div>
          </div>
        </div>
        <div className="event-div-6">
          <div className="event-div-7">
            <div className="event-div-8">
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/0feccbb490c3d8316f0debd570770513734788a12cfe2128b8cee06e30237a9d?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/0feccbb490c3d8316f0debd570770513734788a12cfe2128b8cee06e30237a9d?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0feccbb490c3d8316f0debd570770513734788a12cfe2128b8cee06e30237a9d?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/0feccbb490c3d8316f0debd570770513734788a12cfe2128b8cee06e30237a9d?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/0feccbb490c3d8316f0debd570770513734788a12cfe2128b8cee06e30237a9d?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0feccbb490c3d8316f0debd570770513734788a12cfe2128b8cee06e30237a9d?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/0feccbb490c3d8316f0debd570770513734788a12cfe2128b8cee06e30237a9d?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/0feccbb490c3d8316f0debd570770513734788a12cfe2128b8cee06e30237a9d?placeholderIfAbsent=true&apiKey=9115250ac62743e6bab562981ab6b8d1"
                className="event-img-3"
              />
            </div>
            <div className="event-div-10">
              <div className="event-div-11">
                <div className="argentum-gala-night">Argentum Gala Night</div>
                <div className="event-div-12">
                  <div className=""></div>
                </div>
              </div>
              <div className="step-into-elegance-the-argentum-gala-night-unveiling-our-exclusive-silver-green-collection">
                Step into Elegance: The Argentum Gala Night Unveiling Our
                Exclusive Silver-Green Collection.
              </div>
              <div className="event-div-13" />
              <div className="nov-10-2024-nov-12-2024-7-00-pm-11-00-pm">
                Nov 10, 2024 - Nov 12, 2024, 7:00 PM - 11:00 PM
              </div>
              <div className="casino-du-liban-jounieh-lebanon-2">
                Casino du Liban, Jounieh, Lebanon
              </div>
              <div className="event-div-14">
                <div className="rsvp-now">RSVP Now</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .event-div {
          display: flex;
          align-items: center;
          gap: 40px;
          justify-content: start;
          flex-wrap: wrap;
        }
        .event-div-2 {
          border-radius: 15px;
          box-shadow: 0px 0px 10px rgba(192, 192, 192, 1);
          align-self: stretch;
          display: flex;
          min-width: 240px;
          padding-bottom: 8px;
          flex-direction: column;
          overflow: hidden;
          color: rgba(0, 0, 0, 0.7);
          flex-grow: 1;
          width: 520px;
          margin: auto 0;
          font: 700 20px Lato, sans-serif;
          border: 2px solid rgba(192, 192, 192, 1);
        }
        @media (max-width: 991px) {
          .event-div-2 {
            max-width: 100%;
          }
        }
        .event-img {
          aspect-ratio: 2;
          object-fit: contain;
          object-position: center;
          width: 100%;
        }
        @media (max-width: 991px) {
          .event-img {
            max-width: 100%;
          }
        }
        .event-div-3 {
          display: flex;
          margin-top: 15px;
          width: 100%;
          flex-direction: column;
          padding: 0 22px;
        }
        @media (max-width: 991px) {
          .event-div-3 {
            max-width: 100%;
            padding: 0 20px;
          }
        }
        .the-red-carpet-launch-of-our-new-collection {
          color: rgba(28, 28, 28, 1);
          font: 25px Raleway, sans-serif;
        }
        @media (max-width: 991px) {
          .the-red-carpet-launch-of-our-new-collection {
            max-width: 100%;
          }
        }
        .a-night-where-sustainability-shines-be-among-the-first-to-experience-our-latest-designs-while-supporting-a-greener-planet {
          margin: 15px 10px 0 0;
        }
        @media (max-width: 991px) {
          .a-night-where-sustainability-shines-be-among-the-first-to-experience-our-latest-designs-while-supporting-a-greener-planet {
            max-width: 100%;
          }
        }
        .event-div-4 {
          box-shadow: 0px 0px 4px rgba(192, 192, 192, 1);
          margin-top: 20px;
          width: 598px;
          max-width: 100%;
          height: 0px;
          border: 2px solid rgba(192, 192, 192, 1);
        }
        .oct-30-2024-nov-4-2024-7-00-pm-11-00-pm {
          align-self: start;
          margin-top: 54px;
        }
        @media (max-width: 991px) {
          .oct-30-2024-nov-4-2024-7-00-pm-11-00-pm {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .casino-du-liban-jounieh-lebanon {
          align-self: start;
          margin-top: 10px;
        }
        @media (max-width: 991px) {
          .casino-du-liban-jounieh-lebanon {
            margin-left: 8px;
          }
        }
        .event-div-5 {
          display: flex;
          flex-direction: column;
          border-radius: 20px;
          position: relative;
          margin-top: 12px;
          min-height: 55px;
          width: 100%;
          align-items: left;
          gap: 10px;
          overflow: hidden;
          color: rgba(28, 28, 28, 1);
          justify-content: center;
          padding: 11px 11px 11px 12px;
          font: 15px Poppins, sans-serif;
        }
        .event-img-2 {
          position: absolute;
          inset: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
        .rsvp-now {
          position: relative;
          border-radius: 20px;
          background-color: rgba(192, 192, 192, 1);
          gap: 10px;
          overflow: hidden;
          margin: auto 0;
          padding: 13px 40px;
          width: fit-content;
        }
        @media (max-width: 991px) {
          .rsvp-now {
            padding: 0 20px;
          }
        }
        .event-div-6 {
          align-self: stretch;
          display: flex;
          min-width: 240px;
          flex-direction: column;
          flex-grow: 1;
          width: 520px;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .event-div-6 {
            max-width: 100%;
          }
        }
        .event-div-7 {
          border-radius: 15px;
          box-shadow: 0px 0px 10px rgba(192, 192, 192, 1);
          display: flex;
          padding-bottom: 8px;
          flex-direction: column;
          overflow: hidden;
          border: 2px solid rgba(192, 192, 192, 1);
        }
        @media (max-width: 991px) {
          .event-div-7 {
            max-width: 100%;
          }
        }
        .event-div-8 {
          display: flex;
          flex-direction: column;
          position: relative;
          min-height: 50px;
          text-align: center;
          padding: 255px 52px 0;
          font: 800 30px Raleway, sans-serif;
        }
        @media (max-width: 991px) {
          .event-div-8 {
            max-width: 100%;
            margin-right: 2px;
            padding: 100px 20px 0;
          }
        }
        .event-img-3 {
          position: absolute;
          inset: 0;
          height: 300px;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
        .event-div-9 {
          position: relative;
        }
        .event-div-10 {
          display: flex;
          margin-top: 12px;
          width: 100%;
          flex-direction: column;
          align-items: start;
          color: rgba(0, 0, 0, 0.7);
          font-weight: 700;
          padding: 0 22px;
        }
        @media (max-width: 991px) {
          .event-div-10 {
            max-width: 100%;
            padding: 0 20px;
          }
        }
        .event-div-11 {
          z-index: 1;
          display: flex;
          color: rgba(28, 28, 28, 1);
        }
        @media (max-width: 991px) {
          .event-div-11 {
            margin-left: 8px;
          }
        }
        .argentum-gala-night {
          align-self: start;
          margin-right: -73px;
          flex-basis: auto;
          flex-grow: 1;
          font: 25px Raleway, sans-serif;
        }
        .event-div-12 {
          display: flex;
          flex-direction: column;
          border-radius: 20px;
          position: relative;
          aspect-ratio: 3.254;
          min-height: 55px;
          width: 231px;
          align-items: center;
          gap: 10px;
          overflow: hidden;
          justify-content: center;
          padding: 11px 11px 11px 12px;
          font: 15px Poppins, sans-serif;
        }
        .event-img-4 {
          position: absolute;
          inset: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
        .join-the-community {
          position: relative;
          align-self: stretch;
          border-radius: 20px;
          background-color: rgba(192, 192, 192, 1);
          gap: 10px;
          overflow: hidden;
          margin: auto 0;
          padding: 13px 25px;
        }
        @media (max-width: 991px) {
          .join-the-community {
            padding: 0 20px;
          }
        }
        .step-into-elegance-the-argentum-gala-night-unveiling-our-exclusive-silver-green-collection {
          margin-top: -24px;
          font: 20px Lato, sans-serif;
          font-weight: bold;
        }
        @media (max-width: 991px) {
          .step-into-elegance-the-argentum-gala-night-unveiling-our-exclusive-silver-green-collection {
            max-width: 100%;
          }
        }
        .event-div-13 {
          box-shadow: 0px 0px 4px rgba(192, 192, 192, 1);
          align-self: stretch;
          margin-top: 20px;
          width: 100%;
          height: 0px;
          border: 2px solid rgba(192, 192, 192, 1);
        }
        .nov-10-2024-nov-12-2024-7-00-pm-11-00-pm {
          margin-top: 54px;
          font: 20px Lato, sans-serif;
          font-weight: bold;
        }
        @media (max-width: 991px) {
          .nov-10-2024-nov-12-2024-7-00-pm-11-00-pm {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .casino-du-liban-jounieh-lebanon-2 {
          margin-top: 10px;
          font: 20px Lato, sans-serif;
          font-weight: bold;
        }
        @media (max-width: 991px) {
          .casino-du-liban-jounieh-lebanon-2 {
            margin-left: 8px;
          }
        }
        .event-div-14 {
          display: flex;
          flex-direction: column;
          border-radius: 20px;
          position: relative;
          margin-top: 12px;
          min-height: 55px;
          width: 100%;
          align-items: left;
          gap: 10px;
          overflow: hidden;
          color: rgba(28, 28, 28, 1);
          justify-content: center;
          padding: 11px 11px 11px 12px;
          font: 15px Poppins, sans-serif;
        }
        .event-img-5 {
          position: absolute;
          inset: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
      `}</style>
    </>
  );
}

export default Events;
